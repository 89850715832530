import { Button, Card, Form, Input, Space, notification } from "antd";
import { IS_FORGOT_PASSWORD, REGEX } from "constants/common";
import { useAppContext } from "context/AppContext";
import { useSetPasswordMutation } from "generated/graphql";
import { isTokenExpired } from "helpers/token";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const isTokenExpiredRef = useRef(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useAppContext();

  const [setPasswordMutation, { loading }] = useSetPasswordMutation();

  const emailToken = searchParams.get("email_token") || "";

  const isForgotPassword = localStorage.getItem(IS_FORGOT_PASSWORD)
    ? JSON.parse(localStorage.getItem(IS_FORGOT_PASSWORD) || "")
    : false;

  const onSubmit = async (values: { password: string }) => {
    try {
      const res = await setPasswordMutation({
        variables: {
          password: values.password,
          emailToken: emailToken,
        },
      });
      if (res?.data) {
        notification.success({
          message: t("messages.success.reset_password.message", {
            text: isForgotPassword
              ? t("modal.reset_password.reset.capitalize")
              : t("modal.reset_password.set.capitalize"),
          }),
          description: t("messages.success.reset_password.description", {
            text: isForgotPassword
              ? t("modal.reset_password.reset.lowercase")
              : t("modal.reset_password.set.lowercase"),
          }),
        });
        localStorage.removeItem("isForgotPassword");
        return navigate(`/${language}/sign-in`);
      }
    } catch (error: any) {
      notification.error({
        message: t("messages.error.reset_password.message", {
          text: isForgotPassword
            ? t("modal.reset_password.reset.capitalize")
            : t("modal.reset_password.set.capitalize"),
        }),
        description: error.message,
      });
    }
  };

  useEffect(() => {
    if (
      !isTokenExpiredRef.current &&
      (!emailToken || isTokenExpired(emailToken))
    ) {
      isTokenExpiredRef.current = true;

      notification.error({
        message: t("messages.error.expire_reset_password.message"),
        description: t("messages.error.expire_reset_password.description"),
      });
      return navigate(`/${language}/sign-in`);
    }
  }, [emailToken, navigate, t, language]);

  return (
    <Card style={{ maxWidth: 500, minWidth: 450 }}>
      <Form layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label={t("sign_in.password")}
          name="password"
          rules={[
            {
              required: true,
              message: t("rules.required", { field: "password" }),
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.resolve();
                }

                if (!REGEX.PASSWORD.test(value.toString())) {
                  return Promise.reject(t("rules.password"));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password placeholder={t("placeholder.sign_in.password")} />
        </Form.Item>
        <Form.Item
          label={t("sign_up.confirm_password")}
          name="confirm password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: t("rules.required", { field: "confirm password" }),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t("rules.password_not_match"));
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={t("placeholder.sign_up.confirm_password")}
          />
        </Form.Item>
        <Space align="center" className="w-full" direction="vertical">
          <Button loading={loading} htmlType="submit" type="primary">
            {t("actions.set_password")}
          </Button>
        </Space>
      </Form>
    </Card>
  );
}
