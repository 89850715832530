import { TablePaginationConfig } from "antd";
import i18n from "i18n";

export const SIDER_WIDTH = {
	SM: 80,
	LG: 180,
};

export const ACCESS_TOKEN = "token";

export const PAGE = 1;
export const LIMIT = 10;

export const EMPLOYEE_STATUS = {
	RESIGNED: "r",
	PENDING: "p",
	MONTHLY: "m",
	MONTHLY_P: "l",
	DAILY: "d",
	DAILY_P: "c",
	UNKNOWN: "",
};

export const ROLE = {
	USER: "user",
	ADMIN: "admin",
};
export const ROLE_TEXT = {
	[ROLE.USER]: "role.user",
	[ROLE.ADMIN]: "role.admin",
};

export const USER_STATUS_TEXT = {
	ACTIVE: "status.active",
	INACTIVE: "status.inactive",
};

export const EMPLOYEE_STATUS_TEXT = {
	[EMPLOYEE_STATUS.DAILY]: {
		text: "status.daily",
		color: "#E8B644"
	},
	[EMPLOYEE_STATUS.MONTHLY]: {
		text: "status.monthly",
		color: "#10893f"
	},
	[EMPLOYEE_STATUS.DAILY_P]: {
		text: "status.dailyp",
		color: "#EED59C"
	},
	[EMPLOYEE_STATUS.MONTHLY_P]: {
		text: "status.monthlyp",
		color: "#82BE99"
	},
	[EMPLOYEE_STATUS.PENDING]: {
		text: "status.pending",
		color: "#fFFF00"
	},
	[EMPLOYEE_STATUS.RESIGNED]: {
		text: "status.resigned",
		color: "#f5222d"
	},
	[EMPLOYEE_STATUS.UNKNOWN]: {
		text: "status.unknown",
		color: "#ffffff"
	}
};

export const SALARY_PERIOD_TEXT = {
	[EMPLOYEE_STATUS.DAILY]: "salary_period.day",
	[EMPLOYEE_STATUS.MONTHLY]: "salary_period.month",
	[EMPLOYEE_STATUS.RESIGNED]: "/"
};

export const IS_ACCEPT_COOKIE = "isAcceptCookie";

export const IS_FORGOT_PASSWORD = "isForgotPassword";

export const REGEX = {
	INTEGER: /^\d+$/,
	EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
	PASSWORD:
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
	ALIAS: /^[a-zA-Z0-9]*$/
};

export const PAGINATION_CONFIG: TablePaginationConfig = {
	position: ["bottomLeft"],
	pageSize: 10,
	current: 1,
	pageSizeOptions: [10, 20, 50, 100],
	showSizeChanger: true,
};

export const USER_PERMISSION_ROUTES = ["/", "/payroll-services"];

export const UNLOCKED_IDS = "unlocked_ids";

export const DATA_TYPES = ["Approved List", "Resignation List"];

export const TO_EMAILS = ["operations@mula-x.com"];

export const EXECUTABLE_FILE_TYPES = [".elf"];
export const EXECUTABLE_MIME_TYPES = [""];

export const ATTACH_FILE_TYPES = [".xlsx"];
export const ATTACH_MIME_TYPES = [
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
export const PAYROLL_FILE_TYPES = [".xls", ".xlsx", ".csv"];
export const PAYROLL_MIME_TYPES = [
	"text/csv",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	"application/vnd.ms-excel",
];

export const SALARY_CHANNELS = [
	{
		value: "TH002C2P344",
		labelEn: "MULA Wallet",
		labelTh: "กระเป๋าเงินมูลา",
	},
	{ value: "TH00PPAYID", labelEn: "PromptPay", labelTh: "พร้อมเพย์" },
	{
		value: "TH00BKKB002",
		labelEn: "Bangkok Bank",
		labelTh: "ธนาคารกรุงเทพ",
	},
	{ value: "TH00KASI004", labelEn: "KBANK", labelTh: "ธนาคารกสิกรไทย" },
	{
		value: "TH00KRTH006",
		labelEn: "Krungthai",
		labelTh: "ธนาคารกรุงไทย",
	},
	{
		value: "TH00TMBK011",
		labelEn: "TMBThanachart",
		labelTh: "ธนาคารทีเอ็มบีธนชาติ",
	},
	{
		value: "TH00SICO014",
		labelEn: "SCB",
		labelTh: "ธนาคารไทยพาณิชย์",
	},
	{
		value: "TH00UBOB022",
		labelEn: "CIMB Thai",
		labelTh: "ธนาคารซีไอเอ็มบีไทย",
	},
	{
		value: "TH00UOVB024",
		labelEn: "UOB",
		labelTh: "ธนาคารยูโอบี",
	},
	{
		value: "TH00AYUD025",
		labelEn: "Krungsri",
		labelTh: "ธนาคารกรุงศรี",
	},
	{
		value: "TH00ICBC026",
		labelEn: "Mega",
		labelTh: "ธนาคารเมกะสากลพาณิชย์",
	},
	{
		value: "TH00GSBA030",
		labelEn: "GSB",
		labelTh: "ธนาคารออมสิน",
	},
	{
		value: "TH00GOHU033",
		labelEn: "GHB",
		labelTh: "ธนาคารอาคารสงเคราะห์",
	},
	{
		value: "TH00BAAB034",
		labelEn: "BAAC",
		labelTh: "ธนาคารเพื่อการเกษตรและสหกรณ์",
	},
	{
		value: "TH00TIBT066",
		labelEn: "Islamic Bank",
		labelTh: "ธนาคารอิสลาม",
	},
	{
		value: "TH00TFPC067",
		labelEn: "Tisco",
		labelTh: "ธนาคารทิสโก้",
	},
	{
		value: "TH00KKPB069",
		labelEn: "Kiatnakin",
		labelTh: "ธนาคารเกียรตินาคิน",
	},
	{
		value: "TH00ICBK070",
		labelEn: "ICBC",
		labelTh: "ธนาคารไอซีบีซี",
	},
	{
		value: "TH00THCE071",
		labelEn: "Thai Credit",
		labelTh: "ธนาคารไทยเครดิต",
	},
	{
		value: "TH00LAHR073",
		labelEn: "LH Bank",
		labelTh: "ธนาคารแลนด์ แอนด์ เฮ้าส์",
	},
];

export const PAYROLL_EDITABLE_COLUMNS = [
	"payDate",
	"accruedEarning",
	"salary",
	"paymentAmount",
];

export const LANGUAGE = "language";
export const ALLOW_LANGUAGES =
	process.env.REACT_APP_LANGUAGES?.split(",").map((el) => {
		const [label, value] = el.split("-");

		return {
			label,
			value,
		};
	}) || [];

export const LANGUAGE_KEYS =
	process.env.REACT_APP_LANGUAGES?.split(",").map((el) => {
		const [, value] = el.split("-");

		return value;
	}) || [];
