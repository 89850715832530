import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  notification,
} from "antd";
import { REGEX } from "constants/common";
import { useAppContext } from "context/AppContext";
import { useSignUpMutation } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { SignUpPayload } from "types";

export default function SignUp() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useAppContext();

  const [signUpMutation, { loading }] = useSignUpMutation();

  const onSubmit = async (values: SignUpPayload) => {
    try {
      await signUpMutation({
        variables: values,
      });
      notification.success({
        message: t("messages.success.sign_up.message"),
        description: t("messages.success.sign_up.description"),
      });
      navigate(`/${language}/sign-in`);
    } catch (error: any) {
      notification.error({
        message: t("messages.error.sign_up.message"),
        description: error.message,
      });
    }
  };

  return (
    <Card style={{ maxWidth: 700 }}>
      <Form layout="vertical" onFinish={onSubmit}>
        <Row gutter={16}>
          <Col span={24} sm={12}>
            <Form.Item
              label={t("sign_up.first_name")}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: t("rules.required", {
                    field: t("sign_up.first_name"),
                  }),
                },
              ]}
            >
              <Input placeholder={t("placeholder.sign_up.first_name")} />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              label={t("sign_up.last_name")}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: t("rules.required", {
                    field: t("sign_up.last_name"),
                  }),
                },
              ]}
            >
              <Input placeholder={t("placeholder.sign_up.last_name")} />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              label={t("sign_in.email")}
              name="email"
              rules={[
//                {
//                  type: "email",
//                  message: t("rules.invalid", { field: t("sign_in.email") }),
//                },
                {
                  required: true,
                  message: t("rules.required", { field: t("sign_in.email") }),
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (!REGEX.EMAIL.test(value.toString())) {
                      return Promise.reject(t("rules.email"));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input placeholder={t("placeholder.sign_in.email")} />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              label={t("sign_in.company_id")}
              name="companyRegistrationNumber"
              rules={[
                {
                  required: true,
                  message: t("rules.required", {
                    field: t("sign_in.company_id"),
                  }),
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (!REGEX.INTEGER.test(value.toString())) {
                      return Promise.reject(
                        t("rules.invalid", {
                          field: t("sign_in.company_id"),
                        })
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                maxLength={13}
                className="w-full"
                placeholder={t("placeholder.sign_in.company_id")}
              />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              label={t("sign_in.password")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("rules.required", {
                    field: t("sign_in.password"),
                  }),
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (!REGEX.PASSWORD.test(value.toString())) {
                      return Promise.reject(t("rules.password"));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password placeholder={t("placeholder.sign_in.password")} />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              label={t("sign_up.confirm_password")}
              name="confirm password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t("rules.required", {
                    field: t("sign_up.confirm_password"),
                  }),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t("rules.password_not_match"));
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={t("placeholder.sign_up.confirm_password")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Space className="w-full" align="center" direction="vertical">
          <Button loading={loading} htmlType="submit" type="primary">
            {t("actions.sign_up")}
          </Button>
          <Space>
            <Typography.Text>{t("sign_up.have_account_text")}</Typography.Text>
            <Link to={`/${language}/sign-in`}>{t("actions.sign_in")}</Link>
          </Space>
        </Space>
      </Form>
    </Card>
  );
}
