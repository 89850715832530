import {
    Button,
    Form,
    Modal,
    Row,
    Space,
    Typography,
    notification,
} from "antd";
import { ChangeEvent, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { EXECUTABLE_FILE_TYPES, EXECUTABLE_MIME_TYPES } from "../../../constants/common";
import FetchAPI from "helpers/fetchApi";

const UploadKeyModal = () => {
    const [form] = Form.useForm();
    const [showModal, setShowModal] = useState<boolean>(true);

    const inputFileRef = useRef<any>();

    const { t } = useTranslation();

    const onSubmit = async () => {
        let res: any;
        try {
            Modal.confirm({
                content: t("modal.key.upload.description"),
                okText: t("actions.continue"),
                cancelText: t("actions.cancel"),
                width: 500,
                icon: null,
                onOk: () => {
                    inputFileRef.current.click();
                },
            });

            onCancel();
        } catch (error: any) {
            notification.error({
                message: t("modal.key.upload.message"),
                description: error.message,
            });
        }
    };

    const onCancel = () => {
        form.resetFields();
        setShowModal(false);
    };

    const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        
        if (!file) return;

        const isValidType = EXECUTABLE_MIME_TYPES.some((type) => type === file?.type);

        if (!isValidType) {
            event.target.value = "";
            return notification.error({
                message: t("messages.error.invalid_file.message")
            });
        }

        if (file?.size > 1000000) {
            event.target.value = "";
            return notification.error({
               message: t("messages.error.upload.too_large"),
            });
        }

        event.target.value = "";

        const formdata = new FormData();
        formdata.append(
          "operations",
          '{ "query": "mutation ($file: Upload! ) { uploadKey(file: $file ) { message } }", ' +
          `"variables": { "file": null } }`
        );
        formdata.append("map", '{ "0": ["variables.file"] }');
        formdata.append("0", file, file?.name);

        const res = await FetchAPI.upload(formdata);
    
        if (res.errors?.length) {
            return notification.error({
            message: t("messages.error.upload.message"),
            description: res.errors[0].message,
            });
        }

        return notification.success({
            message: t("messages.success.upload.message"),
        });
    };

    return (
        <Modal
            width={340}
            title={<Typography.Title level={4}>{t("modal.key.upload.title")}</Typography.Title>}
            open={showModal}
            onCancel={onCancel}
            footer={false}
            maskClosable={false}
        >
            <Form
                form={form}
                initialValues={{
                    type: "key",
                }}
                layout="vertical"
                onFinish={onSubmit}
            >
                {" "}
                <Row justify="end">
                    <Space size={16}>
                        <Button
                            // disabled={exportPayrollLoading || exportPaymentLoading}
                            onClick={onCancel}
                        >
                            {t("actions.cancel")}
                        </Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                            // loading={exportPayrollLoading || exportPaymentLoading}
                        >
                            {t("actions.open")}
                        </Button>
                    </Space>
                </Row>
            </Form>
            <input
                ref={inputFileRef}
                type="file"
                accept={EXECUTABLE_FILE_TYPES.join(",")}
                style={{ display: "none" }}
                onChange={onFileChange}
            />
        </Modal>
    );
};

export default UploadKeyModal;
