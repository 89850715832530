import { ConfigProvider, Typography } from "antd";
import { IS_ACCEPT_COOKIE } from "constants/common";
import { useAppContext } from "context/AppContext";
import { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { RouterProvider } from "react-router-dom";
import router from "router";
import theme from "theme";

function App() {
  const { language } = useAppContext();
  const { t } = useTranslation();

  useEffect(() => {
    ConfigProvider.config({
      theme: {
        token: {
          fontFamily: theme.font[language],
        },
      },
    });
  }, [language]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: theme.font[language],
        },
      }}
    >
      <RouterProvider router={router} />
      <CookieConsent
        location="bottom"
        buttonText={t("actions.accept_cookie")}
        cookieName={IS_ACCEPT_COOKIE}
        style={{ background: "#fff", alignItems: "center" }}
        buttonStyle={{
          fontSize: "14px",
          backgroundColor: "#1677ff",
          color: "#fff",
          padding: "4px 15px",
          height: "32px",
          borderRadius: "6px",
          fontFamily: theme.font[language],
        }}
      >
        <Typography.Title level={5}>{t("cookie.title")}</Typography.Title>
        <Typography.Text>{t("cookie.description")}</Typography.Text>
      </CookieConsent>
    </ConfigProvider>
  );
}

export default App;
