import { ApolloProvider } from "@apollo/client";
import client from "helpers/apolloClient";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/styles/index.css";
import reportWebVitals from "./reportWebVitals";

import AppProvider from "context/AppContext";
import "helpers/date";
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AppProvider>
        <App />
      </AppProvider>
    </ApolloProvider>
  </React.StrictMode>
);

reportWebVitals();
