import {
  Button,
  Card,
  Form,
  Input,
  Space,
  Typography,
  notification,
} from "antd";
import { IS_FORGOT_PASSWORD, REGEX } from "constants/common";
import { useAppContext } from "context/AppContext";
import {
  ResetPasswordMutationVariables,
  useResetPasswordMutation,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const { language } = useAppContext();

  const [resetPasswordMutation, { loading }] = useResetPasswordMutation();

  const onSubmit = async (values: ResetPasswordMutationVariables) => {
    try {
      const res = await resetPasswordMutation({ variables: values });
      if (res?.data) {
        notification.success({
          message: t("messages.success.forgot_password.message"),
          description: t("messages.success.forgot_password.description"),
        });

        localStorage.setItem(IS_FORGOT_PASSWORD, JSON.stringify(true));
      }
    } catch (error: any) {
      notification.error({
        message: t("messages.error.forgot_password.message"),
        description: error.message,
      });
    }
  };
  return (
    <Card style={{ maxWidth: 420, minWidth: 360 }}>
      <Form layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label={t("sign_in.email")}
          name="email"
          rules={[
            {
              type: "email",
              message: t("rules.invalid", { field: t("sign_in.email") }),
            },
            {
              required: true,
              message: t("rules.required", { field: t("sign_in.email") }),
              whitespace: false,
            },
          ]}
        >
          <Input placeholder={t("placeholder.sign_in.email")} />
        </Form.Item>
        <Form.Item
          label={t("sign_in.company_id")}
          name="companyRegistrationNumber"
          rules={[
            {
              required: true,
              message: t("rules.required", {
                field: t("sign_in.company_id"),
              }),
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.resolve();
                }

                if (!REGEX.INTEGER.test(value.toString())) {
                  return Promise.reject(
                    t("rules.invalid", { field: t("sign_in.company_id") })
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            className="w-full"
            placeholder={t("placeholder.sign_in.company_id")}
          />
        </Form.Item>
        <Space align="center" className="w-full" direction="vertical">
          <Button loading={loading} htmlType="submit" type="primary">
            {t("actions.forgot_password")}
          </Button>
          <Space>
            <Typography.Text>{t("sign_up.have_account_text")}</Typography.Text>
            <Link to={`/${language}/sign-in`}>{t("actions.sign_in")}</Link>
          </Space>
        </Space>
      </Form>
    </Card>
  );
}
