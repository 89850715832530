import { Col, Form, Input, Modal, Row, Select, Typography } from "antd";
import { useAddUserMutation, useUpdateUserMutation } from "generated/graphql";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { User } from "types";

export interface UsersFormModalProps {
  item?: User;
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  title: string;
}

export default function UsersFormModal({
  open,
  onOk,
  onCancel,
  title,
  item,
}: UsersFormModalProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [addUserMutation, { loading, error }] = useAddUserMutation();

  const [showEmailError, setShowEmailError] = useState(false);

  const handleOk = useCallback(() => {
    form.submit();
  }, [form]);

  const [updateUserMutation] = useUpdateUserMutation();

  const onSubmit = async (values: any) => {
    try {
      if (item) {
        await updateUserMutation({
          variables: { ...values, userId: item.id },
        });
      } else {
        await addUserMutation({ variables: values });
      }
      form.resetFields();
      setShowEmailError(false);
      onOk();
    } catch (error) {
      if (error) {
        setShowEmailError(true);
      }
    }
  };
  const handleCancel = () => {
    form.resetFields();
    setShowEmailError(false);
    onCancel();
  };

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        email: item?.email || "",
        firstName: item?.first_name || "",
        lastName: item?.last_name || "",
        status: item.status,
      });
    } else {
      form.resetFields();
    }
  }, [item, form]);

  return (
    <Modal
      width={400}
      centered
      title={title}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t("actions.save")}
      cancelText={t("actions.cancel")}
      confirmLoading={loading}
      maskClosable={false}
    >
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={t("user.columns.email")}
              required
              name="email"
              rules={[
                {
                  required: true,
                  message: t("rules.required", {
                    field: t("user.columns.email"),
                  }),
                },
                {
                  type: "email",
                  message: t("rules.invalid", {
                    field: t("user.columns.email"),
                  }),
                },
              ]}
            >
              <Input
                placeholder={t("placeholder.user.email")}
                disabled={!!item}
              />
              {error && showEmailError && (
                <Typography.Text type="danger">
                  {t("rules.exist_email")}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t("user.columns.first_name")}
              required
              name="firstName"
              rules={[
                {
                  required: true,
                  message: t("rules.required", {
                    field: t("user.columns.first_name"),
                  }),
                },
              ]}
            >
              <Input placeholder={t("placeholder.user.first_name")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t("user.columns.last_name")}
              required
              name="lastName"
              rules={[
                {
                  required: true,
                  message: t("rules.required", {
                    field: t("user.columns.last_name"),
                  }),
                },
              ]}
            >
              <Input placeholder={t("placeholder.user.last_name")} />
            </Form.Item>
          </Col>
          {item && (
            <Col span={24}>
              <Form.Item
                label={t("user.columns.status")}
                required
                name="status"
                rules={[
                  {
                    required: true,
                    message: t("rules.required", {
                      field: t("user.columns.status"),
                    }),
                  },
                ]}
              >
                <Select placeholder={t("placeholder.user.status")}>
                  <Select.Option value={true}>
                    {t("status.active")}
                  </Select.Option>
                  <Select.Option value={false}>
                    {t("status.inactive")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
}
