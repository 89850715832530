import { Col, Row, Typography } from "antd";
import ProfileDropdown from "components/atoms/ProfileDropdown";
import { ROLE_TEXT } from "constants/common";
import { useAppContext } from "context/AppContext";
import { DateFormat, formatDate } from "helpers/date";
import { useTranslation } from "react-i18next";

export default function HeaderContent() {
	const { userInfo } = useAppContext();
	const { t } = useTranslation();

	return (
		<Row justify="space-between" style={{ padding: "0 16px", height: "100%" }}>
			<Col
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						gap: 4,
					}}
				>
					{userInfo?.last_login && (
						<Typography.Text>
							{t("previos_login_time")}:{" "}
							{formatDate(userInfo?.last_login, DateFormat.dayMonthYearHour)}
						</Typography.Text>
					)}
					{userInfo?.exp && (
						<Typography.Text>
							{t("expire_time")}:{" "}
							{formatDate(
								new Date(userInfo?.exp * 1000).toString(),
								DateFormat.dayMonthYearHour
							)}
						</Typography.Text>
					)}
				</div>
			</Col>
			<Col>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						height: "100%",
						gap: 16,
					}}
				>
					<Row style={{ flexDirection: "column" }} align="bottom">
						<Typography.Text>{`${userInfo?.alias}  |  ${userInfo?.email}`}</Typography.Text>
						<Typography.Text>
							{t(ROLE_TEXT[userInfo?.role || ""])}
						</Typography.Text>
					</Row>
					<ProfileDropdown />
				</div>
			</Col>
		</Row>
	);
}
