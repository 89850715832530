import { Menu, MenuProps } from "antd";
import { Link, useLocation } from "react-router-dom";
import { DollarOutlined, UserOutlined } from "@ant-design/icons";
import { useMemo } from "react";
import { ACCESS_TOKEN, ROLE } from "constants/common";
import { parseJwt } from "helpers/token";
import { useAppContext } from "context/AppContext";
import { useTranslation } from "react-i18next";

export default function MainMenu() {
  const location = useLocation();
  const { language } = useAppContext();
  const { t } = useTranslation();

  const defaultKey = useMemo(
    () => [location.pathname.split("/").slice(0, 3).join("/")],
    [location.pathname]
  );

  const userInfo = useMemo(() => {
    const token = localStorage.getItem(ACCESS_TOKEN);

    return token ? parseJwt(token) : {};
  }, []);

  const items = useMemo(() => {
    const items: MenuProps["items"] = [
      {
        key: `/${language}/payroll-services`,
        icon: <DollarOutlined />,
        label: (
          <Link to={`/${language}/payroll-services`}>{t("menu.payroll")}</Link>
        ),
      },
    ];

    if (userInfo.role === ROLE.ADMIN) {
      items.unshift({
        key: `/${language}/users`,
        icon: <UserOutlined />,
        label: <Link to={`/${language}/users`}>{t("menu.users")}</Link>,
      });
    }

    return items;
  }, [userInfo, language, t]);

  return <Menu selectedKeys={defaultKey} items={items} />;
}
