import { LANGUAGE, LANGUAGE_KEYS } from "constants/common";
import { useAppContext } from "context/AppContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, useParams } from "react-router-dom";

export default function DetectLanguage() {
  const params = useParams();
  const { setLanguage } = useAppContext();
  const { i18n } = useTranslation();

  const language = params.lang || "";

  const isExisLanguage = LANGUAGE_KEYS.some((lang) => lang === language);

  useEffect(() => {
    if (isExisLanguage) {
      i18n.changeLanguage(language);
      localStorage.setItem(LANGUAGE, language);
      setLanguage(language);
    }
  }, [isExisLanguage, language, setLanguage, i18n]);

  if (!isExisLanguage) {
    return <Navigate to="not-found" />;
  }

  return <Outlet />;
}
