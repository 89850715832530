import { Layout } from "antd";
import AdminContent from "components/organisms/AdminContent";
import { AdminFooter } from "components/organisms/AdminFooter";
import AdminHeader from "components/organisms/AdminHeader";
import AdminSider from "components/organisms/AdminSider";
import { SIDER_WIDTH } from "constants/common";
import { useMemo, useState } from "react";
import Style from "./styles";

export default function AdminTemplate() {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const siderWidth = useMemo(() => {
    if (collapsed) return SIDER_WIDTH.SM;

    return SIDER_WIDTH.LG;
  }, [collapsed]);

  return (
    <Style collapsed={collapsed.toString()} width={`${siderWidth}px`}>
      <Layout hasSider>
        <AdminSider
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          siderWidth={siderWidth}
        />
        <Layout className="site-layout" style={{ marginLeft: siderWidth }}>
          <AdminHeader />
          <AdminContent />
          <AdminFooter />
        </Layout>
      </Layout>
    </Style>
  );
}
