import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Table, Tag, Typography, notification } from "antd";

import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { ROLE, ROLE_TEXT, USER_STATUS_TEXT } from "constants/common";
import { useResendMailMutation } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { User } from "types";

export interface UsersTableProps {
	items: User[];
	loading: boolean;
	pagination?: TablePaginationConfig | false;
	onEdit: (record: User) => void;
	onDeleteUser: (record: User) => void;
}

export default function UsersTable({
	items,
	loading,
	onEdit,
	onDeleteUser,
	pagination,
}: UsersTableProps) {
	const [resendMailMutation] = useResendMailMutation();
	const { t } = useTranslation();

	const onMenuClick = (key: string, record: User) => {
		if (key === "edit") {
			return onEdit(record);
		}

		if (key === "delete") {
			return onDeleteUser(record);
		}
		return onResendEmail(record);
	};

	const onResendEmail = async (record: User) => {
		try {
			const res = await resendMailMutation({
				variables: {
					userId: record.id || 0,
				},
			});

			if (res?.data) {
				notification.success({
					message: t("messages.success.resend_email.message"),
					description: t("messages.success.resend_email.description"),
				});
			}
		} catch (error: any) {
			notification.error({
				message: t("messages.error.resend_email"),
				description: error.message,
			});
		}
	};

	const colums: ColumnsType<User> = [
		{
			key: "email",
			dataIndex: "email",
			title: t("user.columns.email"),
		},
		{
			key: "name",
			dataIndex: "name",
			title: t("user.columns.full_name"),
			render: (_, record) => (
				<Typography.Text>{`${record.first_name} ${
					record.last_name || ""
				}`}</Typography.Text>
			),
		},
		{
			key: "role",
			dataIndex: "role",
			title: t("user.columns.role"),
			align: "center",
			render: (value) => {
				return (
					<Tag color={value === ROLE.ADMIN ? "#EB382A" : "#E8B644"}>
						{t(ROLE_TEXT[value])}
					</Tag>
				);
			},
		},
		{
			key: "status",
			dataIndex: "status",
			title: t("user.columns.status"),
			align: "center",
			render: (value) => {
				return (
					<Tag color={value ? "success" : "error"}>
						{value ? t(USER_STATUS_TEXT.ACTIVE) : t(USER_STATUS_TEXT.INACTIVE)}
					</Tag>
				);
			},
		},

		{
			key: "actions",
			fixed: "right",
			title: t("user.columns.actions"),
			align: "center",
			render: (_, record) => {
				const items = [
					{
						label: t("actions.edit"),
						key: "edit",
					},
					{
						label: t("actions.delete"),
						key: "delete",
					},
				];

				if (!record.status) {
					items.push({
						label: t("actions.resend"),
						key: "resend-email",
					});
				}

				return (
					<Dropdown
						menu={{
							items,
							onClick: ({ key }) => onMenuClick(key, record),
						}}
						trigger={["click"]}
						placement="bottomRight"
					>
						<Button style={{ padding: "4px 8px" }}>
							<MoreOutlined />
						</Button>
					</Dropdown>
				);
			},
		},
	];
	return (
		<Table
			columns={colums}
			dataSource={items || []}
			scroll={{ x: "max-content" }}
			rowKey="id"
			loading={loading}
			pagination={pagination}
			bordered
		/>
	);
}
