import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, Space, TablePaginationConfig, notification } from "antd";
import PayrollFormModal from "components/organisms/PayrollFormModal";
import PayrollModalExport from "components/organisms/PayrollModalExport";
import PayrollModalUpload from "../../components/organisms/PayrollModalUpload";
import PayrollServiceFilter from "components/organisms/PayrollServiceFilter";
import PayrollServiceTable from "components/organisms/PayrollServiceTable";
import {
  LIMIT,
  PAGE,
  PAGINATION_CONFIG,
  PAYROLL_MIME_TYPES,
} from "constants/common";
import {
  useDeleteEmployeeMutation,
  useDownloadEmployeesMutation,
  useGetEmployeesQuery,
  useSaveEmployeesMutation,
  useSetPayDateMutation,
} from "generated/graphql";
import { DateFormat, formatDate } from "helpers/date";
import { exportCsvFile } from "helpers/export";
import FetchAPI from "helpers/fetchApi";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Employee, PayrollQuery } from "types";
import Style from "./styles";
import { useTranslation } from "react-i18next";

export default function PayrollServices() {
  const [query, setQuery] = useState<PayrollQuery>({
    page: 1,
    limit: LIMIT,
    employeeStatus: "",
  });
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalExport, setshowModalExport] = useState<boolean>(false);
  const [showModalUpload, setshowModalUpload] = useState<boolean>(false);
  const tableRef = useRef<any>();
  const payrollExportRef = useRef<any>();

  const [selectedItem, setSelectedItem] = useState<Employee | undefined>();
  const { data, loading, refetch, error } = useGetEmployeesQuery({
    variables: query,
  });
  const [deleteEmployeeMutation] = useDeleteEmployeeMutation();
  const [saveEmployeesMutation, { loading: saveActionLoading }] =
    useSaveEmployeesMutation();

  const [downloadEmployeesMutation, { loading: downloadActionLoading }] =
    useDownloadEmployeesMutation();

  const [setPayDateMutation, { loading: isSaveCalendarLoading }] =
    useSetPayDateMutation();

  const { t } = useTranslation();

  const { items, pagination } = useMemo(() => {
    return {
      items:
        (data?.getEmployees?.employees as Employee[])?.map((el, index) => {
          const currentPage = query.page || PAGE;
          const pageSize = query.limit || LIMIT;
          const startIndex = (currentPage - 1) * pageSize + 1;
          return {
            ...el,
            order: startIndex + index,
            searchQuery: searchQuery,
          };
        }) || [],
      pagination: data?.getEmployees?.pagination
        ? {
            ...PAGINATION_CONFIG,
            current: data.getEmployees.pagination.page || PAGE,
            total: data.getEmployees.pagination.total || 0,
            pageSize: data.getEmployees.pagination.limit || LIMIT,
            showTotal: () =>
              `${t("payroll.pagination.total_item", {
                total: data?.getEmployees?.pagination?.total,
              })}`,
          }
        : undefined,
    };
  }, [data?.getEmployees, query, searchQuery, t]);

  const onSearch = (payload: { search: string }) => {
    onConfirmWarnModal(() => {
      setSearchQuery(payload.search?.trim());
    });
  };

  const onPageChange = (pagination: TablePaginationConfig, filter: any) => {
    onConfirmWarnModal(() => {
      setQuery((prev) => ({
        ...prev,
        page: pagination.current || PAGE,
        limit: pagination.pageSize || LIMIT,
        employeeStatus: filter.employeeStatus?.toString() ?? "",
      }));
    });
  };

  const onAddEmployee = () => {
    onConfirmWarnModal(() => {
      setSelectedItem(undefined);
      setShowModal(true);
    });
  };

  const onCloseModal = () => {
    setShowModal(false);
    setSelectedItem(undefined);
  };

  const onEdit = useCallback((record: Employee) => {
    setShowModal(true);
    setSelectedItem(record);
  }, []);

  const onDelete = (record: Employee) => {
    Modal.confirm({
      title: t("modal.payroll.delete.message"),
      icon: <ExclamationCircleFilled />,
      content: t("modal.payroll.delete.description"),
      onOk: async () => {
        await deleteEmployeeMutation({
          variables: {
            ids: [record.id || null],
          },
        });
        await refetch();
      },
      cancelText: t("actions.cancel"),
      okText: t("actions.ok"),
    });
    return;
  };

  const onSubmitModal = () => {
    onCloseModal();
    setSelectedItem(undefined);
  };

  const onClose = () => {
    setshowModalExport(false);
    setshowModalUpload(false);
  };

  const onExport = () => {
    onConfirmWarnModal(() => {
      setshowModalExport(true);
    });
  };

  const onUpload = () => {
    onConfirmWarnModal(() => {
      setshowModalUpload(true);
/*      Modal.confirm({
        content: t("modal.payroll.upload.description"),
        okText: t("actions.continue"),
        cancelText: t("actions.cancel"),
        width: 500,
        icon: null,
        onOk: () => {
          inputFileRef.current.click();
        },
      }); */
    });
  };

  const onDownload = () => {
    onConfirmWarnModal(async () => {
      const res = await downloadEmployeesMutation();

      exportCsvFile(
        res.data?.downloadEmployees?.data || [],
        res.data?.downloadEmployees?.file_name || ""
      );
    });
  };

  const onSave = async () => {
    try {
      const records: Employee[] = tableRef.current?.getChangedRecords();

      if (!records?.length) return;

			const res = await saveEmployeesMutation({
				variables: {
					input: records.map((el) => ({
						id: el.id,
						pay_date: el.pay_date
							? formatDate(el.pay_date, DateFormat.dayMonthYear)
							: null,
						payment_amount: el.payment_amount || 0,
						salary: el.salary || 0,
						accrued_earning: el.accrued_earning || 0,
					})),
				},
			});

      if (res.data?.saveEmployees) {
        notification.success({
          message: res.data.saveEmployees,
        });

        await refetch();
        await payrollExportRef.current.refetchPayDate();
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const onUndo = () => {
    tableRef.current.onUndo();
  };

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) return;

    const isValidType = PAYROLL_MIME_TYPES.some((type) => type === file?.type);

    if (!isValidType) {
      event.target.value = "";
      return notification.error({
        message: t("messages.error.upload.message"),
      });
    }

    event.target.value = "";

    const formdata = new FormData();
    formdata.append(
      "operations",
      '{ "query": "mutation ($file: Upload!) { uploadEmployees(file: $file) { message } }", "variables": { "file": null } }'
    );
    formdata.append("map", '{ "0": ["variables.file"] }');
    formdata.append("0", file, "file");

    const res = await FetchAPI.upload(formdata);

    if (res.errors?.length) {
      return notification.error({
        message: t("messages.error.upload.message"),
        description: res.errors[0].message,
      });
    }

    if (query.page === PAGE) {
      await refetch({
        limit: LIMIT,
        page: PAGE,
      });
    } else {
      setQuery((prev) => ({ ...prev, page: PAGE }));
    }

    await payrollExportRef.current.refetchPayDate();

    return notification.success({
      message: t("messages.success.upload.message"),
    });
  };

  const onSaveCalendar = (payload: { ids: number[]; payDate: string }) => {
    onConfirmWarnModal(async () => {
      const res = await setPayDateMutation({
        variables: payload,
      });

      notification.success({
        message: res.data?.setPayDate,
      });
      tableRef.current.resetSelectedRows();
      await refetch();
      await payrollExportRef.current?.refetchPayDate();
    });
  };

  const onConfirmWarnModal = (cb: () => void) => {
    const records: Employee[] = tableRef.current?.getChangedRecords();

    if (!records?.length) return cb();

    Modal.confirm({
      content: t("modal.payroll.warnning.description"),
      okText: t("actions.continue_without_save"),
      cancelText: t("actions.save"),
      width: 500,
      icon: null,
      cancelButtonProps: {
        loading: saveActionLoading,
      },
      onOk: () => {
        onUndo();
        cb();
      },
      onCancel: async () => {
        await onSave();
        cb();
      },
    });
  };

  useEffect(() => {
    if (error) {
      notification.error({
        message: error.message,
      });
    }
  }, [error]);

  return (
    <Style>
      <Space className="w-full" direction="vertical" size={16}>
        <PayrollServiceFilter
          onSearch={onSearch}
          onAddEmployee={onAddEmployee}
          onExport={onExport}
          onUpload={onUpload}
          onDownload={onDownload}
          onUndo={onUndo}
          onSave={onSave}
          saveActionLoading={saveActionLoading}
          downloadActionLoading={downloadActionLoading}
        />
        <PayrollServiceTable
          ref={tableRef}
          items={items}
          loading={loading}
          pagination={pagination}
          onPageChange={onPageChange}
          onEdit={onEdit}
          onDelete={onDelete}
          refetch={refetch}
          refetchPayDate={() => payrollExportRef.current?.refetchPayDate()}
          onSaveCalendar={onSaveCalendar}
          isSaveCalendarLoading={isSaveCalendarLoading}
        />
        <PayrollFormModal
          item={selectedItem}
          title={`${
            selectedItem
              ? t("modal.payroll.edit_employee")
              : t("modal.payroll.add_employee")
          }`}
          open={showModal}
          onOk={onSubmitModal}
          onCancel={onCloseModal}
          refresh={refetch}
        />
        <PayrollModalExport
          ref={payrollExportRef}
          open={showModalExport}
          onClose={onClose}
        />
        <PayrollModalUpload
          open={showModalUpload}
          onClose={onClose}
          onFileChange={onFileChange}
        />
      </Space>
    </Style>
  );
}
