import { CalendarOutlined, CloseOutlined } from "@ant-design/icons";
import { DatePicker, DatePickerProps, Spin, notification } from "antd";
import DatePickerFooter from "components/atoms/DatePickerFooter";
import { usePayrollContext } from "context/PayrollContext";
import { Dayjs } from "dayjs";
import { DateFormat, formatDate } from "helpers/date";
import { useState } from "react";
import { Employee } from "types";
type PayrollDatePickerProps = DatePickerProps & {
  employeeId?: number;
  selectedRows: Employee[];
  onSaveCalendar: (payload: { ids: number[]; payDate: string }) => void;
  loading?: boolean;
};

export default function PayrollDatePicker(props: PayrollDatePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<Dayjs | null>(null);

  const { unlockedIds } = usePayrollContext();

  const onDatePickerClick = () => {
    if (props.loading) return;

    setIsOpen(!isOpen);

    if (isOpen && props.defaultValue) {
      setValue(props.defaultValue);
    }
  };

  const onDateChange = (value: Dayjs | null) => {
    setValue(value);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onSave = async () => {
    try {
      const ids = props.selectedRows
        .filter((row) => unlockedIds.some((id) => id === row.id))
        .map((el) => el.id);

      const payload = {
        ids,
        payDate: value
          ? formatDate(value.toISOString(), DateFormat.dayMonthYear)
          : "",
      };

      props.onSaveCalendar(payload);
      onClose();
    } catch (error: any) {
      notification.error({
        message: "Save data is failed",
        description: error.message,
      });
    }
  };

  return (
    <DatePicker
      {...props}
      suffixIcon={
        isOpen ? (
          <CloseOutlined onClick={onDatePickerClick} />
        ) : (
          <CalendarOutlined onClick={onDatePickerClick} />
        )
      }
      renderExtraFooter={() => (
        <Spin spinning={props.loading}>
          <DatePickerFooter
            onSave={onSave}
            disabled={!props.selectedRows.length}
          />
        </Spin>
      )}
      defaultValue={props.defaultValue}
      open={isOpen}
      value={value}
      onClick={onDatePickerClick}
      onChange={onDateChange}
    />
  );
}
