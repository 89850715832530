import { Button, Col, Row } from "antd";
import Style from "./styles";
import { useTranslation } from "react-i18next";

interface DatePickerFooterProps {
  onSave: () => void;
  disabled: boolean;
}

export default function DatePickerFooter({
  onSave,
  disabled,
}: DatePickerFooterProps) {
  const { t } = useTranslation();

  return (
    <Style>
      <Row justify="end" gutter={[8, 8]}>
        <Col>
          <Button size="small" onClick={onSave} type="default">
            {t("actions.apply_all")}
          </Button>
        </Col>
        <Col>
          <Button
            size="small"
            disabled={disabled}
            onClick={onSave}
            type="primary"
          >
            {t("actions.apply_selected")}
          </Button>
        </Col>
      </Row>
    </Style>
  );
}
