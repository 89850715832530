import styled from "styled-components";

const Style = styled.div`
  height: 100%;

  .auth-container {
    height: 100%;
    width: 100%;
  }

  .auth-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 40px;
    align-items: center;
    padding: 24px;
  }

  .logo {
    width: 256px;
    height: 56px;
  }

  .logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export default Style;
