import { theme } from "antd";
import { Footer } from "antd/es/layout/layout";
import { useTranslation } from "react-i18next";

export function AdminFooter() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { t } = useTranslation();

  return (
    <Footer
      style={{
        background: colorBgContainer,
      }}
      className="layout-footer"
    >
      {t("footer")}
    </Footer>
  );
}
