import { FormInstance } from "antd";
import { createContext, useContext } from "react";

const PayrollEditableContext = createContext<FormInstance<any> | null>(null);

export const usePayrollEditableContext = () => {
  return useContext(PayrollEditableContext);
};

export default PayrollEditableContext;
