import Sider from "antd/es/layout/Sider";
import SiderLogo from "components/atoms/SiderLogo";
import MainMenu from "components/molecules/MainMenu";

interface Props {
  collapsed: boolean;
  onCollapse: (value: boolean) => void;
  siderWidth: number;
}

export default function AdminSider({
  collapsed,
  onCollapse,
  siderWidth,
}: Props) {
  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
      }}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      collapsedWidth={siderWidth}
      breakpoint="lg"
      theme="light"
      width={siderWidth}
    >
      <SiderLogo />
      <MainMenu />
    </Sider>
  );
}
