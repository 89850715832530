/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, Form, InputNumber } from "antd";
import { usePayrollEditableContext } from "context/PayrollEditableContext";
import dayjs, { Dayjs } from "dayjs";
import { DateFormat } from "helpers/date";
import { FocusEvent, ReactNode, useEffect } from "react";
import { Employee } from "types";
import Styles from "./styles";

export interface IPayrollEditableCellProps {
  record: Employee;
  onSave: (record: Employee) => void;
  name: string;
  inputType?: "number" | "date";
  addonAfter?: ReactNode;
  editable: boolean;
  children?: ReactNode;
}

const PayrollEditableCell = ({
  record,
  onSave,
  name,
  inputType = "number",
  addonAfter,
  editable,
  children,
  ...restProps
}: IPayrollEditableCellProps) => {
  const form = usePayrollEditableContext();

  const onFocus = (event: FocusEvent<HTMLInputElement, Element>) => {
    event.target.select();
  };

  const onInputChange = (event: FocusEvent<HTMLInputElement, Element>) => {
    const value = event.target.value;

    onSubmit({
      [name]: value,
    });
  };
  const onDateChange = (value: Dayjs | null) => {
    onSubmit({
      [name]: value,
    });
  };

	const onSubmit = (values: Record<string, any>) => {
		if (inputType === "number" && values[name]) {
			values[name] = values[name] ? parseFloat(values[name]) : 0;
		}

		onSave({
			...record,
			...values,
			isEditted: values[name] !== (record as any)[name],
		});
	};

  const getValue = () => {
    if (name === "pay_date")
      return (record as any)[name] ? dayjs((record as any)[name]) : null;
    return (record as any)[name] || 0;
  };

  useEffect(() => {
    if (editable) {
      
      const value = getValue();
      form && form.setFieldsValue({ [name]: value ?? '' });
    }
  }, [form, record, name, editable]);

  let childNode = children;

  if (editable) {
    childNode = (
      <Styles>
        <Form.Item name={name} style={{ margin: 0 }}>
          {inputType === "number" ? (
            <InputNumber
              style={{ width: addonAfter ? 160 : 100 }}
              disabled={record.isLocked}
              addonAfter={addonAfter}
              stringMode
              step="0.01"
              controls={false}
              onBlur={onInputChange}
              onFocus={onFocus}
            />
          ) : (
            <DatePicker
              style={{ width: 120 }}
              format={DateFormat.dayMonthYear}
              onChange={onDateChange}
              disabled={record.isLocked}
            />
          )}
        </Form.Item>
      </Styles>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
export default PayrollEditableCell;
