import {
  Button,
  Form,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Typography,
  notification,
} from "antd";
import {
  useDownloadPaymentDataMutation,
  useDownloadPayrollDataMutation,
  useGetEmployeePaydatesQuery,
} from "generated/graphql";
import { exportCsvFile } from "helpers/export";
import { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";

interface ExportData {
  type: "payroll" | "payment";
  date: string;
}

interface PayrollModalExportProps {
  open: boolean;
  onClose: () => void;
}
const PayrollModalExport = forwardRef(
  ({ onClose, open }: PayrollModalExportProps, ref) => {
    const [form] = Form.useForm();

    const [exportPaymentMutation, { loading: exportPaymentLoading }] =
      useDownloadPaymentDataMutation();
    const [exportPayrollMutation, { loading: exportPayrollLoading }] =
      useDownloadPayrollDataMutation();
    const { data, refetch } = useGetEmployeePaydatesQuery();
    const { t } = useTranslation();

    const isPaymentType = Form.useWatch("type", form) === "payment";
    const datePaymentExport = Form.useWatch("date", form);

    const onSubmit = async (values: ExportData) => {
      let res: any;
      try {
        if (values.type === "payroll") {
          res = await exportPayrollMutation();
          exportCsvFile(
            res.data.downloadPayrollData.data,
            res.data.downloadPayrollData.file_name
          );
        } else {
          res = await exportPaymentMutation({
            variables: {
              payDate: values.date,
            },
          });

          exportCsvFile(
            res.data.downloadPaymentData.data,
            res.data.downloadPaymentData.file_name
          );
        }

        onCancel();
      } catch (error: any) {
        notification.error({
          message: t("modal.payroll.export.message"),
          description: error.message,
        });
      }
    };
    const onCancel = () => {
      form.resetFields();
      onClose();
    };

    useImperativeHandle(ref, () => {
      return {
        refetchPayDate: () => {
          refetch();
        },
      };
    });

    return (
      <Modal
        width={340}
        open={open}
        title={<Typography.Title level={4}>{t("modal.payroll.export.title")}</Typography.Title>}
        onCancel={onCancel}
        footer={false}
        maskClosable={false}
      >
        <Form
          form={form}
          initialValues={{
            type: "payroll",
          }}
          layout="vertical"
          onFinish={onSubmit}
        >
          {" "}
          <Form.Item name="type">
            <Radio.Group style={{ display: "flex", flexDirection: "column" }}>
              <Radio value="payroll">{t("modal.payroll.export.payroll")}</Radio>
              <Radio value="payment">{t("modal.payroll.export.payment")}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="date">
            <Select
              placeholder={t("modal.payroll.export.select_paydate")}
              disabled={!isPaymentType}
              style={{ width: "100%", maxHeight: "200px", overflowY: "auto" }}
            >
              {data?.getEmployeePaydates?.pay_dates?.map((payDate, index) => (
                <Select.Option key={index} value={payDate}>
                  {payDate}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Row justify="end">
            <Space size={16}>
              <Button
                disabled={exportPayrollLoading || exportPaymentLoading}
                onClick={onCancel}
              >
                {t("actions.cancel")}
              </Button>
              <Button
                disabled={!datePaymentExport && isPaymentType}
                htmlType="submit"
                type="primary"
                loading={exportPayrollLoading || exportPaymentLoading}
              >
                {t("actions.save")}
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    );
  }
);

export default PayrollModalExport;
