import { Form } from "antd";
import PayrollEditableContext from "context/PayrollEditableContext";

interface PayrollEditableRowProps {
  index: number;
}

const PayrollEditableRow: React.FC<PayrollEditableRowProps> = ({
  index,
  ...props
}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <PayrollEditableContext.Provider value={form}>
        <tr {...props} />
      </PayrollEditableContext.Provider>
    </Form>
  );
};

export default PayrollEditableRow;
