import { KeyOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, MenuProps } from "antd";
import { useAppContext } from "context/AppContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ProfileDropdown() {
  const { setIsLogined, setLanguage } = useAppContext();
  const { t } = useTranslation();

  const language = process.env.REACT_APP_DEFAULT_LANGUAGE || "";

  const items: MenuProps["items"] = [
    // {
    //   key: "/profile",
    //   icon: <UserOutlined />,
    //   label: "My Profile",
    // },
    {
      key: "upload-key",
      icon: <KeyOutlined />,
      label: t("actions.load_key"),
    },
    {
      key: `/${language}/sign-in`,
      icon: <LogoutOutlined />,
      label: t("actions.logout"),
    },
  ].map((item) => ({
    ...item,
    label: <Link to={item.key}>{item.label}</Link>,
  }));

  const onMenuClick = (e: any) => {
    if (e.key === "upload-key") {
    } else {
      localStorage.clear();
      setIsLogined(false);
      setLanguage(language);
    }
  };

  return (
    <Dropdown
      menu={{ items, onClick: onMenuClick }}
      placement="bottomRight"
      trigger={["click"]}
    >
      <Avatar size={32} icon={<UserOutlined />} />
    </Dropdown>
  );
}
