import { Button, Result, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const Forbidden: React.FC = () => (
  <Row justify="center" align="middle" style={{ height: "100%" }}>
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Link to="/">
          <Button type="primary">Back</Button>
        </Link>
      }
    />
  </Row>
);

export default Forbidden;
