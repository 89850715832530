import AdminTemplate from "components/templates/Admin";
import AuthTemplate from "components/templates/Auth";
import PayrollServices from "pages/PayrollServices";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import UploadKeyModal from "components/organisms/UploadKeyModal";
import Users from "pages/Users";

import PayrollProvider from "context/PayrollContext";
import Forbidden from "pages/Forbidden";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";
import { createHashRouter } from "react-router-dom";
import DetectLanguage from "./DetectLanguage";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Home from "pages/Home";
import NotFound from "pages/NotFound";

const router = createHashRouter(
  [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/:lang",
      element: <DetectLanguage />,
      children: [
        {
          element: (
            <PrivateRoute>
              <AdminTemplate />
            </PrivateRoute>
          ),
          children: [
            {
              path: "upload-key",
              element: <UploadKeyModal />,
            },
            {
              path: "users",
              element: <Users />,
            },
            {
              path: "payroll-services",
              element: (
                <PayrollProvider>
                  <PayrollServices />
                </PayrollProvider>
              ),
            },
          ],
        },
        {
          element: (
            <PublicRoute>
              <AuthTemplate />
            </PublicRoute>
          ),
          children: [
            {
              path: "sign-in",
              element: <SignIn />,
            },
            {
              path: "sign-up",
              element: <SignUp />,
            },
            {
              path: "forgot-password",
              element: <ForgotPassword />,
            },
            {
              path: "reset-password",
              element: <ResetPassword />,
            },
          ],
        },
      ],
    },
    {
      path: "forbidden",
      element: <Forbidden />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
  { basename: process.env.REACT_APP_HOME_PAGE }
);

export default router;
