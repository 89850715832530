import { notification } from "antd";
import { ROLE, USER_PERMISSION_ROUTES } from "constants/common";
import { useAppContext } from "context/AppContext";
import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";

export default function PrivateRoute({ children }: { children: ReactElement }) {
	const location = useLocation();
	const { isLogined, userInfo, language } = useAppContext();
	const { t } = useTranslation();

	const isAllowed = useMemo(() => {
		if (userInfo && userInfo.role === ROLE.ADMIN) return true;

		const allowRoutes = USER_PERMISSION_ROUTES.map((el) =>
			el === "/" ? `/${language}` : `/${language}${el}`
		);

		return allowRoutes.some((route) => route === location.pathname);
	}, [location, userInfo, language]);

	if (!isLogined) {
		notification.error({
			message: t("messages.error.expire_token.message"),
			description: t("messages.error.expire_token.description"),
		});
		localStorage.clear();
		return <Navigate to={`/${language}/sign-in`} />;
	}

	if (!isAllowed) return <Navigate to={`/forbidden`} />;

	return children;
}
