import { Card } from "antd";
import { Content } from "antd/es/layout/layout";
import { Outlet } from "react-router-dom";

export default function AdminContent() {
  return (
    <Content className="layout-content">
      <Card>
        <Outlet />
      </Card>
    </Content>
  );
}
