import styled from "styled-components";

const Style = styled.div`
  .row-locked .ant-table-cell-fix-right {
    background-color: #ebebeb;
  }

  .locked-icon svg,
  .delete-icon svg {
    fill: #dc2626;
  }

  .unlocked-icon svg {
    fill: #16a34a;
  }

  & .ant-table-thead {
    .ant-picker {
      border: unset;
      background-color: unset;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:hover {
        color: rgba(0, 0, 0, 0.88);
        background-color: rgba(0, 0, 0, 0.06);
      }
    }

    .ant-picker {
      padding: 7px;
      cursor: pointer;
    }

    .ant-picker-input > input {
      display: none;
    }

    .ant-picker-input > .ant-picker-suffix {
      margin-inline-start: unset;

      svg {
        color: rgba(0, 0, 0, 0.88);
      }
    }

    .date-picker-footer {
      padding: 16px;
    }

    .ant-picker-clear {
      display: none;
    }

    .ant-picker-disabled {
      cursor: not-allowed;
    }
  }
`;

export default Style;
