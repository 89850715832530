/* eslint-disable react-hooks/exhaustive-deps */
import {
	DeleteOutlined,
	LockOutlined,
	MoreOutlined,
	UnlockOutlined,
} from "@ant-design/icons";
import {
	Button,
	Dropdown,
	Modal,
	Space,
	Table,
	Tag,
	Typography,
	notification,
} from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { FilterValue } from "antd/es/table/interface";
import PayrollDatePicker from "components/molecules/PayrollDatePicker";
import PayrollEditableCell from "components/molecules/PayrollEditableCell";
import PayrollEditableRow from "components/molecules/PayrollEditableRow";
import {
	EMPLOYEE_STATUS,
	EMPLOYEE_STATUS_TEXT,
	PAYROLL_EDITABLE_COLUMNS,
	SALARY_CHANNELS,
	SALARY_PERIOD_TEXT,
	UNLOCKED_IDS,
} from "constants/common";
import { usePayrollContext } from "context/PayrollContext";
import dayjs from "dayjs";
import {
	useClearAccruedEarningMutation,
	useClearPaydateMutation,
	useClearPaymentAmountMutation,
	useClearSalaryMutation,
} from "generated/graphql";
import { getHighlightedText } from "helpers/highlightText";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { Employee } from "types";
import Style from "./styles";

export interface PayrollServiceTableProps {
	items: Employee[];
	loading: boolean;
	pagination?: TablePaginationConfig | false;
	onPageChange?: (
		values: TablePaginationConfig,
		filters: Record<string, FilterValue | null>
	) => void;
	onEdit: (record: Employee) => void;
	onDelete: (record: Employee) => void;
	refetch: () => void;
	refetchPayDate: () => void;
	onSaveCalendar: (payload: { ids: number[]; payDate: string }) => void;
	isSaveCalendarLoading?: boolean;
}

const PayrollServiceTable = forwardRef(
	(
		{
			items,
			loading,
			pagination,
			onPageChange,
			onEdit,
			onDelete,
			refetch,
			refetchPayDate,
			onSaveCalendar,
			isSaveCalendarLoading,
		}: PayrollServiceTableProps,
		ref: any
	) => {
		const [selectedRows, setSelectedRows] = useState<Employee[]>([]);
		const [dataSource, setDataSource] = useState<Employee[]>([]);

		const [clearSalaryMutation, { loading: clearSalaryLoading }] =
			useClearSalaryMutation();
		const [
			clearAccruedEarningMutation,
			{ loading: clearAccruedEarningLoading },
		] = useClearAccruedEarningMutation();
		const [clearPaydateMutation, { loading: clearPaydateLoading }] =
			useClearPaydateMutation();
		const [clearPaymentAmountMutation, { loading: clearPaymentAmountLoading }] =
			useClearPaymentAmountMutation();

		const { setUnlockedIds, unlockedIds } = usePayrollContext();
		const { t } = useTranslation();

		const onMenuClick = (key: string, record: Employee) => {
			if (key === "edit") return onEdit(record);
			return onDelete(record);
		};

		const deleteColumn = (content: string, type: string) => {
			if (!selectedRows.length) return onShowWarnModal(content, type, []);

			const ids = selectedRows
				.filter((row) => unlockedIds.some((id) => id === row.id))
				.map((el) => el.id);

			return ids.length && onClearData(type, ids);
		};

		const onShowWarnModal = (content: string, type: string, ids: number[]) => {
			Modal.confirm({
				content,
				okText: t("actions.confirm"),
				cancelText: t("actions.cancel"),
				width: 500,
				icon: null,
				okButtonProps: {
					loading:
						clearSalaryLoading ||
						clearAccruedEarningLoading ||
						clearPaydateLoading ||
						clearPaymentAmountLoading,
				},
				onOk: () => onClearData(type, ids),
			});
		};

		const onClearData = async (type: string, ids: number[]) => {
			try {
				if (type === "salary") {
					const res = await clearSalaryMutation({
						variables: {
							ids,
						},
					});

					notification.success({
						message: res.data?.clearSalary,
					});
				} else if (type === "accrued_earning") {
					const res = await clearAccruedEarningMutation({
						variables: {
							ids,
						},
					});

					notification.success({
						message: res.data?.clearAccruedEarning,
					});
				} else if (type === "payment_amount") {
					const res = await clearPaymentAmountMutation({
						variables: {
							ids,
						},
					});
					notification.success({
						message: res.data?.clearPaymentAmount,
					});
				} else {
					const res = await clearPaydateMutation({
						variables: {
							ids,
						},
					});
					notification.success({
						message: res.data?.clearPaydate,
					});
				}

				setSelectedRows([]);
				await refetch();
				await refetchPayDate();
			} catch (error: any) {
				notification.error({
					message: t("messages.error.clear.message"),
					description: error.message,
				});
			}
		};

		const rowSelection = {
			onChange: (_: any, selectedRows: Employee[]) => {
				setSelectedRows(selectedRows);
			},
		};

		const onUnlock = async (id: number) => {
			const ids = [...unlockedIds];
			ids.push(id);

			localStorage.setItem(UNLOCKED_IDS, JSON.stringify(ids));

			setUnlockedIds(ids);
		};

		const onLock = (id: number) => {
			const ids = unlockedIds.filter((e) => e !== id);

			localStorage.setItem(UNLOCKED_IDS, JSON.stringify(ids));

			setUnlockedIds(ids);
		};

		const onUnlockSelectedRow = () => {
			let ids = [];
			if (!selectedRows.length) {
				ids = items.map((e) => e.id);
			} else {
				ids = selectedRows.map((e) => e.id);
			}

			localStorage.setItem(UNLOCKED_IDS, JSON.stringify(ids));

			setUnlockedIds(ids);
			selectedRows.length && setSelectedRows([]);
		};

		const onSave = (row: Employee) => {
			const newData = [...dataSource];
			const index = newData.findIndex((item) => row.id === item.id);
			const item = newData[index];
			newData.splice(index, 1, {
				...item,
				...row,
			});
			setDataSource(newData);
		};

		const onUndo = () => {
			setDataSource(items);
		};

		const handleSaveCalendar = async (payload: {
			ids: number[];
			payDate: string;
		}) => {
			onSaveCalendar(payload);
		};

		useEffect(() => {
			setDataSource(
				items.map((el) => ({
					...el,
					isLocked: !unlockedIds.some((id: number) => id === el.id),
				}))
			);
		}, [items]);

		useEffect(() => {
			setDataSource(
				dataSource.map((el) => ({
					...el,
					isLocked: !unlockedIds.some((id: number) => id === el.id),
				}))
			);
		}, [unlockedIds]);

		useImperativeHandle(ref, () => {
			return {
				getChangedRecords: () => {
					return dataSource.filter((e) => e.isEditted);
				},
				onUndo,
				resetSelectedRows: () => {
					selectedRows.length && setSelectedRows([]);
				},
			};
		});

		const defaultColumns: ColumnsType<Employee> = [
			{
				key: "order",
				dataIndex: "order",
				title: t("payroll.columns.no"),
				align: "center",
			},
			{
				key: "employeeId",
				dataIndex: "employee_id",
				title: t("payroll.columns.employee_id"),
				align: "center",
				render: (value, record) =>
					getHighlightedText(value, record.searchQuery),
			},
			{
				key: "TIN",
				dataIndex: "TIN",
				title: t("payroll.columns.tin"),
				align: "center",
				render: (value, record) =>
					getHighlightedText(value, record.searchQuery),
			},
			{
				key: "name",
				dataIndex: "name",
				title: t("payroll.columns.full_name"),
				render: (_, record) =>
					!record.first_name && !record.last_name
						? "-"
						: getHighlightedText(
								`${record.first_name || ""} ${record?.last_name || ""}`,
								record.searchQuery
						  ),
				align: "center",
			},
			{
				key: "employeeStatus",
				dataIndex: "employee_status",
				title: t("payroll.columns.status"),
				align: "center",
				filters: [
					{
						text: t(EMPLOYEE_STATUS_TEXT[EMPLOYEE_STATUS.DAILY].text),
						value: EMPLOYEE_STATUS.DAILY
					},
					{
						text: t(EMPLOYEE_STATUS_TEXT[EMPLOYEE_STATUS.DAILY_P].text),
						value: EMPLOYEE_STATUS.DAILY_P
					},
					{
						text: t(EMPLOYEE_STATUS_TEXT[EMPLOYEE_STATUS.MONTHLY].text),
						value: EMPLOYEE_STATUS.MONTHLY
					},
					{
						text: t(EMPLOYEE_STATUS_TEXT[EMPLOYEE_STATUS.MONTHLY_P].text),
						value: EMPLOYEE_STATUS.MONTHLY_P
					},
					{
						text: t(EMPLOYEE_STATUS_TEXT[EMPLOYEE_STATUS.RESIGNED].text),
						value: EMPLOYEE_STATUS.RESIGNED
					},
					{
						text: t(EMPLOYEE_STATUS_TEXT[EMPLOYEE_STATUS.PENDING].text),
						value: EMPLOYEE_STATUS.PENDING
					},
					{
						text: t(EMPLOYEE_STATUS_TEXT[EMPLOYEE_STATUS.UNKNOWN].text),
						value: EMPLOYEE_STATUS.UNKNOWN
					}
				],
				render: (value) => {
					return value ? (
						<Tag
							style={{ width: 70, textAlign: "center" }}
							color={EMPLOYEE_STATUS_TEXT[value]?.color}
						>
							{t(EMPLOYEE_STATUS_TEXT[value]?.text)}
						</Tag>
					) : (
						"-"
					);
				},
			},
			{
				key: "routingNumber",
				dataIndex: "routing_number",
				title: t("payroll.columns.salary_channel"),
				align: "center",
				render: (value) =>
					SALARY_CHANNELS.find((el) => el.value === value)?.labelEn || "-",
			},
			{
				key: "accountNumber",
				dataIndex: "account_number",
				title: t("payroll.columns.account_no"),
				align: "center",
				render: (value) => (
					<Typography.Text>
						{value?.slice(1, -3).replace(/./g, "*") + value?.slice(-3) || "-"}
					</Typography.Text>
				),
			},
			{
				key: "actions",
				title: t("payroll.columns.action"),
				align: "center",
				render: (_, record) => {
					return (
						<Dropdown
							menu={{
								items: [
									{
										label: t("actions.edit"),
										key: "edit",
									},
									{
										label: t("actions.delete"),
										key: "delete",
									},
								],
								onClick: ({ key }) => onMenuClick(key, record),
							}}
							trigger={["click"]}
							placement="bottomRight"
						>
							<Button size="small" style={{ padding: "0 4px" }}>
								<MoreOutlined />
							</Button>
						</Dropdown>
					);
				},
			},
			{
				key: "lock",
				dataIndex: "isLocked",
				fixed: "right",
				title: (
					<Button type="text" size="small" onClick={onUnlockSelectedRow}>
						<UnlockOutlined className="unlocked-icon" />
					</Button>
				),
				align: "center",
				render: (value, record) =>
					value ? (
						<Button
							type="text"
							size="small"
							onClick={() => onUnlock(record.id)}
						>
							<LockOutlined className="locked-icon" />
						</Button>
					) : (
						<Button type="text" size="small" onClick={() => onLock(record.id)}>
							<UnlockOutlined className="unlocked-icon" />
						</Button>
					),
			},
			{
				key: "salary",
				dataIndex: "salary",
				fixed: "right",
				title: (
					<Space size={4}>
						{t("payroll.columns.salary_wage")}
						<Button
							type="text"
							size="small"
							icon={<DeleteOutlined className="delete-icon" />}
							onClick={() =>
								deleteColumn(
									t("modal.payroll.clear.description_pop_up", {
										field: `${t("payroll.columns.salary")}`,
										text: `${t("payroll.columns.wage")}`,
									}),
									"salary"
								)
							}
						/>
					</Space>
				),
				align: "right",
			},
			{
				key: "accruedEarning",
				dataIndex: "accrued_earning",
				fixed: "right",
				title: (
					<Space size={4}>
						{t("payroll.columns.accrued_earning")}
						<Button
							type="text"
							size="small"
							icon={<DeleteOutlined className="delete-icon" />}
							onClick={() =>
								deleteColumn(
									t("modal.payroll.clear.description", {
										field: t("payroll.columns.accrued_earning"),
									}),
									"accrued_earning"
								)
							}
						/>
					</Space>
				),
				align: "center",
			},
			{
				key: "paymentAmount",
				dataIndex: "payment_amount",
				title: (
					<Space size={4}>
						{t("payroll.columns.payment_amount")}
						<Button
							type="text"
							size="small"
							icon={<DeleteOutlined className="delete-icon" />}
							onClick={() =>
								deleteColumn(
									t("modal.payroll.clear.description", {
										field: t("payroll.columns.payment_amount"),
									}),
									"payment_amount"
								)
							}
						/>
					</Space>
				),
				align: "right",
				fixed: "right",
			},
			{
				key: "payDate",
				dataIndex: "pay_date",
				fixed: "right",
				align: "center",
				title: (
					<Space size={4}>
						{t("payroll.columns.pay_date")}
						<PayrollDatePicker
							showToday={false}
							defaultValue={dayjs(new Date())}
							selectedRows={selectedRows}
							onSaveCalendar={handleSaveCalendar}
							loading={isSaveCalendarLoading}
						/>
						<Button
							type="text"
							size="small"
							icon={<DeleteOutlined className="delete-icon" />}
							onClick={() =>
								deleteColumn(
									t("modal.payroll.clear.description", {
										field: t("payroll.columns.pay_date"),
									}),
									"pay_date"
								)
							}
						/>
					</Space>
				),
			},
		];

		const components = {
			body: {
				row: PayrollEditableRow,
				cell: PayrollEditableCell,
			},
		};

		const columns = defaultColumns.map((col) => {
			const editable = PAYROLL_EDITABLE_COLUMNS.some((e) => e === col.key);

			if (!editable) return col;

			return {
				...col,
				onCell: (record: Employee): any => ({
					record,
					onSave,
					editable: true,
					name: (col as any).dataIndex,
					inputType: col.key === "payDate" ? "date" : "number",
					addonAfter: col.key === "salary" &&
						record.employee_status &&
						SALARY_PERIOD_TEXT[record.employee_status] && (
							<div style={{ fontSize: 12, minWidth: 36 }}>
								{`${
									record.employee_status &&
									SALARY_PERIOD_TEXT[record.employee_status] !==
										SALARY_PERIOD_TEXT[EMPLOYEE_STATUS.RESIGNED]
										? `/${t(SALARY_PERIOD_TEXT[record.employee_status])}`
										: t(SALARY_PERIOD_TEXT[record.employee_status])
								}`}
							</div>
						),
				}),
			};
		});

		return (
			<Style ref={ref}>
				<Table
					columns={columns}
					dataSource={dataSource || []}
					scroll={{ x: "max-content" }}
					rowKey="id"
					loading={loading}
					pagination={pagination}
					rowSelection={{
						type: "checkbox",
						selectedRowKeys: selectedRows.map((e) => e.id),
						...rowSelection,
					}}
					onChange={onPageChange}
					rowClassName={(record) => (record.isLocked ? "row-locked" : "")}
					bordered
					components={components}
				/>
			</Style>
		);
	}
);

export default PayrollServiceTable;
