import { Col, Form, Input, Modal, Row, Select, notification } from "antd";
import { EMPLOYEE_STATUS, SALARY_CHANNELS } from "constants/common";
import dayjs from "dayjs";
import {
  useAddEmployeeMutation,
  useUpdateEmployeeMutation,
} from "generated/graphql";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Employee } from "types";

export interface PayrollFormModalProps {
  item?: Employee;
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  title: string;
  refresh: () => void;
}

export default function PayrollFormModal({
  open,
  onOk,
  onCancel,
  title,
  item,
  refresh,
}: PayrollFormModalProps) {
  const [form] = Form.useForm();
  const [addEmployeeMutation, { loading }] = useAddEmployeeMutation();
  const [editEmployeeMutation] = useUpdateEmployeeMutation();
  const { t } = useTranslation();

  const onSubmit = async (values: any) => {
    try {
      if (item) {
        await editEmployeeMutation({
          variables: {
            ...values,
            updateEmployeeId: item.id,
            payDate: values.payDate
              ? dayjs(values.payDate).format("DD/MM/YYYY")
              : undefined,
            accountNumber: values?.accountNumber
              ? values.accountNumber.toString()
              : undefined,
          },
        });
      } else {
        await addEmployeeMutation({
          variables: {
            ...values,
            tin: values.tin.toString(),
            accountNumber: values?.accountNumber
              ? values.accountNumber.toString()
              : undefined,
            payDate: values.payDate
              ? dayjs(values.payDate).format("DD/MM/YYYY")
              : undefined,
          },
        });
      }
      form.resetFields();
      onOk();
      refresh();
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };
  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        employeeId: item.employee_id,
        tin: item.TIN,
        firstName: item.first_name,
        lastName: item.last_name,
        employeeStatus: item.employee_status,
        routingNumber: item.routing_number,
        accountNumber: item.account_number,
        accruedEarning: item.accrued_earning,
        paymentAmount: item.payment_amount,
        salary: item.salary,
        payDate: item.pay_date ? dayjs(item.pay_date) : null,
      });
    } else {
      form.resetFields();
    }
  }, [item, form]);

  return (
    <Modal
      width={600}
      centered
      title={title}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t("actions.save")}
      cancelText={t("actions.cancel")}
      confirmLoading={loading}
      maskClosable={false}
    >
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              name="tin"
              label={t("payroll.columns.tin")}
              required
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(
                        t("rules.required", {
                          field: "Tax Identification Number",
                        })
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                disabled={!!item}
                placeholder={t("placeholder.payroll.tin")}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("payroll.columns.employee_id")}
              name="employeeId"
            >
              <Input placeholder={t("placeholder.payroll.employee_id")} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="firstName" label={t("payroll.columns.first_name")}>
              <Input placeholder={t("placeholder.payroll.first_name")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="lastName" label={t("payroll.columns.last_name")}>
              <Input placeholder={t("placeholder.payroll.last_name")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="employeeStatus"
              label={t("payroll.columns.status")}
            >
              <Select placeholder={t("placeholder.payroll.status")}>
                <Select.Option value={EMPLOYEE_STATUS.DAILY}>
                  Daily
                </Select.Option>
                <Select.Option value={EMPLOYEE_STATUS.MONTHLY}>
                  Monthly
                </Select.Option>
                <Select.Option value={EMPLOYEE_STATUS.RESIGNED}>
                  Resigned
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("payroll.columns.salary_channel")}
              name="routingNumber"
            >
              <Select placeholder={t("placeholder.payroll.salary_channel")}>
                {SALARY_CHANNELS.map((channel, index) => (
                  <Select.Option key={index} value={channel.value}>
                    {channel.labelEn}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("payroll.columns.account_no")}
              name="accountNumber"
            >
              <Input placeholder={t("placeholder.payroll.account_no")} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
