import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ACCESS_TOKEN, LANGUAGE } from "constants/common";
import { isTokenExpired } from "./token";
import router from "router";
import { notification } from "antd";
import i18n from "i18n";

export const EXPRESS_URL = process.env.REACT_APP_BACKEND;
export const GRAPHQL_URL = `${EXPRESS_URL}/graphql`;

const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
  credentials: "include",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(ACCESS_TOKEN) || "";
  const language =
    localStorage.getItem(LANGUAGE) ||
    process.env.REACT_APP_DEFAULT_LANGUAGE ||
    "";

  if (token && isTokenExpired(token)) {
    notification.error({
      message: i18n.t("messages.error.expire_token.message"),
      description: i18n.t("messages.error.expire_token.description"),
    });
    localStorage.clear();
    return router.navigate(`/${language}/sign-in`);
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      language,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

export default client;
