import {
  ExclamationCircleFilled,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import { Modal, Space } from "antd";
import UsersFilter from "components/organisms/UsersFilter";
import UsersFormModal from "components/organisms/UsersFormModal";
import UsersTable from "components/organisms/UsersTable";
import { useAppContext } from "context/AppContext";
import {
  useCloseAccountMutation,
  useDeleteUserMutation,
  useDownloadEmployeesMutation,
  useGetUsersQuery,
} from "generated/graphql";
import { exportCsvFile } from "helpers/export";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { User } from "types";
import AliasFormModal from "../../components/organisms/AliasFormModal";
export default function Users() {
  const { setIsLogined, setLanguage } = useAppContext();
  const language = process.env.REACT_APP_DEFAULT_LANGUAGE || "";
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAliasModal, setShowAliasModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<User | undefined>();
  const { data, loading, refetch } = useGetUsersQuery();
  const [deleteUserMutation] = useDeleteUserMutation();
  const [downloadEmployeesMutation] = useDownloadEmployeesMutation();
  const [closeAccount] = useCloseAccountMutation();
  const { t } = useTranslation();

  const { items } = useMemo(() => {
    return {
      items:
        (data?.getUsers as User[])?.map((el, index) => ({
          ...el,
          order: index + 1,
        })) || [],
    };
  }, [data]);

  const onEditUser = (record: User) => {
    setShowModal(true);
    setSelectedItem(record);
  };

  const onCloseAccount = () => {
    Modal.confirm({
      title: t("modal.user.close_account.message"),
      icon: <ExclamationCircleTwoTone twoToneColor="#ff4d4f" />,
      content: t("modal.user.close_account.description"),
      onOk: async () => {
        try {
          const res = await downloadEmployeesMutation();
          exportCsvFile(
            res.data?.downloadEmployees?.data || [],
            res.data?.downloadEmployees?.file_name || ""
          );
          await closeAccount();

          localStorage.clear();
          setIsLogined(false);
          setLanguage(language);
        } catch (error) {
          console.error("Error closing account:", error);
        }
      },
      cancelText: t("actions.cancel"),
      okText: t("actions.ok"),
    });
  };
  const onSetAlias = () => {
    setShowAliasModal(true);
  };
  const onAddUsers = () => {
    if (items.length >= 5) {
      Modal.confirm({
        title: t("messages.error.add_user.message"),
        icon: <ExclamationCircleFilled />,
        content: t("messages.error.add_user.description"),
        okText: t("actions.ok"),
        cancelButtonProps: {style: {display: 'none'}}
      });
    } else {
      setSelectedItem(undefined);
      setShowModal(true);
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
    setSelectedItem(undefined);
  };

  const onSubmitModal = () => {
    onCloseModal();
    setSelectedItem(undefined);
    refetch();
  };
  const onDelete = (record: User) => {
    Modal.confirm({
      title: t("modal.user.delete.message"),
      icon: <ExclamationCircleFilled />,
      content: t("modal.user.delete.description"),
      onOk: async () => {
        await deleteUserMutation({
          variables: {
            deleteUserId: record.id || 0,
          },
        });
        await refetch();
      },
      okText: t("actions.ok"),
      cancelText: t("actions.cancel"),
    });
    return;
  };
  const handleDeleteUser = (record: User) => {
    onDelete(record);
  };

  const onCancel = () => {
    setShowAliasModal(false);
  };

  return (
    <Space className="w-full" direction="vertical" size={16}>
      <UsersFilter onAddUsers={onAddUsers} onCloseAccount={onCloseAccount} onSetAlias={onSetAlias} />
      <UsersTable
        items={items}
        loading={loading}
        pagination={false}
        onEdit={onEditUser}
        onDeleteUser={handleDeleteUser}
      />
      <UsersFormModal
        item={selectedItem}
        title={`${
          selectedItem ? t("modal.user.edit_user") : t("modal.user.add_user")
        }`}
        open={showModal}
        onOk={onSubmitModal}
        onCancel={onCloseModal}
      />
      <AliasFormModal
        open={showAliasModal}
        onCancel={onCancel}
      />
    </Space>
  );
}
