import {
    Button,
    Form,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Typography,
    notification,
} from "antd";
import { ChangeEvent, forwardRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ATTACH_FILE_TYPES, PAYROLL_FILE_TYPES, ATTACH_MIME_TYPES, TO_EMAILS, DATA_TYPES } from "../../../constants/common";
import FetchAPI from "helpers/fetchApi";

interface UploadData {
    type: "payroll" | "attachment";
    email: string;
}
  
interface PayrollModalUploadProps {
    open: boolean;
    onClose: () => void;
    onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
const PayrollModalUpload = forwardRef(
    ({ onClose, open, onFileChange }: PayrollModalUploadProps) => {
        const [form] = Form.useForm();

        const inputFileRef = useRef<any>();
        const inputAttachFileRef = useRef<any>();

        const { t } = useTranslation();

        const isAttachmentType = Form.useWatch("type", form) === "attachment";
        const emailAttachment = Form.useWatch("email", form);
        const attachmentType = Form.useWatch("datatype", form);

        const onSubmit = async (values: UploadData) => {
            let res: any;
            try {
                if (values.type === "payroll") {
                    Modal.confirm({
                        content: t("modal.payroll.upload.description"),
                        okText: t("actions.continue"),
                        cancelText: t("actions.cancel"),
                        width: 500,
                        icon: null,
                        onOk: () => {
                            inputFileRef.current.click();
                        },
                    });
                } else {
                    inputAttachFileRef.current.click();
                    /* res = await exportPaymentMutation({
                        variables: {
                            payDate: values.date,
                        },
                    }); */
                }
  
                onCancel();
            } catch (error: any) {
                notification.error({
                    message: t("modal.payroll.upload.message"),
                    description: error.message,
                });
            }
        };

        const onCancel = () => {
            form.resetFields();
            onClose();
        };

        const onAttachFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.[0];
        
            if (!file) return;

            const isValidType = ATTACH_MIME_TYPES.some((type) => type === file?.type);

            if (!isValidType) {
              event.target.value = "";
              return notification.error({
                message: t("messages.error.invalid_file.message")
              });
            }

            if (file?.size > 1000000) {
                event.target.value = "";
                return notification.error({
                  message: t("messages.error.upload.too_large"),
                });
            }

            event.target.value = "";

            const formdata = new FormData();
            formdata.append(
              "operations",
              '{ "query": "mutation ($file: Upload!, $email: String!, $type: String) { uploadEmailAttachment(file: $file, email: $email, type: $type) { message } }", ' +
              `"variables": { "file": null, "email": "${emailAttachment}", "type": "${attachmentType}" } }`
            );
            formdata.append("map", '{ "0": ["variables.file"] }');
            formdata.append("0", file, file?.name);

            const res = await FetchAPI.upload(formdata);
        
            if (res.errors?.length) {
              return notification.error({
                message: t("messages.error.upload.message"),
                description: res.errors[0].message,
              });
            }

            return notification.success({
              message: t("messages.success.upload.message"),
            });
        };
        
        return (
            <Modal
                width={340}
                open={open}
                title={<Typography.Title level={4}>{t("modal.payroll.upload.title")}</Typography.Title>}
                onCancel={onCancel}
                footer={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    initialValues={{
                        type: "payroll",
                    }}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    {" "}
                    <Form.Item name="type">
                        <Radio.Group style={{ display: "flex", flexDirection: "column" }}>
                            <Radio value="payroll">{t("modal.payroll.upload.payroll")}</Radio>
                            <Radio value="attachment">{t("modal.payroll.upload.attachment")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="datatype">
                        <Select
                            placeholder={t("modal.payroll.upload.select_datatype")}
                            disabled={!isAttachmentType}
                            style={{ width: "100%", maxHeight: "200px", overflowY: "auto" }}
                        >
                            {DATA_TYPES.map((datatype, index) => (
                                <Select.Option key={index} value={datatype}>
                                    {datatype}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="email">
                        <Select
                            placeholder={t("modal.payroll.upload.select_email")}
                            disabled={!isAttachmentType}
                            style={{ width: "100%", maxHeight: "200px", overflowY: "auto" }}
                        >
                            {TO_EMAILS.map((email, index) => (
                                <Select.Option key={index} value={email}>
                                    {email}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Row justify="end">
                        <Space size={16}>
                            <Button
                                // disabled={exportPayrollLoading || exportPaymentLoading}
                                onClick={onCancel}
                            >
                                {t("actions.cancel")}
                            </Button>
                            <Button
                                disabled={!emailAttachment && isAttachmentType}
                                htmlType="submit"
                                type="primary"
                                // loading={exportPayrollLoading || exportPaymentLoading}
                            >
                                {t("actions.open")}
                            </Button>
                        </Space>
                    </Row>
                </Form>
                <input
                    ref={inputFileRef}
                    type="file"
                    style={{ display: "none" }}
                    accept={PAYROLL_FILE_TYPES.join(",")}
                    onChange={onFileChange}
                />
                <input
                    ref={inputAttachFileRef}
                    type="file"
                    style={{ display: "none" }}
                    accept={ATTACH_FILE_TYPES.join(",")}
                    onChange={onAttachFileChange}
                />
            </Modal>
        );
    }
);
  
export default PayrollModalUpload;