import { theme } from "antd";
import { Header } from "antd/es/layout/layout";
import HeaderContent from "components/molecules/HeaderContent";

export default function AdminHeader() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Header className="layout-header" style={{ background: colorBgContainer }}>
      <HeaderContent />
    </Header>
  );
}
