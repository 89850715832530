import {
  DownloadOutlined,
  SaveOutlined,
  SearchOutlined,
  UndoOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
export interface IPayrollServiceFilterProps {
  onSearch: (values: { search: string }) => void;
  onAddEmployee: (e: MouseEvent<HTMLElement>) => void;
  onExport: (e: MouseEvent<HTMLElement>) => void;
  onUpload: (e: MouseEvent<HTMLElement>) => void;
  onDownload: (e: MouseEvent<HTMLElement>) => void;
  onUndo: () => void;
  onSave: () => void;
  saveActionLoading?: boolean;
  downloadActionLoading?: boolean;
}

export default function PayrollServiceFilter({
  onSearch,
  onAddEmployee,
  onExport,
  onUpload,
  onDownload,
  onUndo,
  onSave,
  saveActionLoading,
  downloadActionLoading,
}: IPayrollServiceFilterProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <Form form={form} onFinish={onSearch}>
      <Row gutter={[12, 12]} justify="space-between">
        <Col>
          <Row gutter={[12, 12]}>
            <Col>
              <Form.Item name="search" noStyle>
                <Input
                  placeholder={`${t("placeholder.payroll.search")}...`}
                  allowClear
                  prefix={<SearchOutlined />}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  {t("placeholder.payroll.search")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row gutter={[12, 12]}>
            <Col>
              <Form.Item>
                <Button onClick={onUndo}>
                  <UndoOutlined />
                  {t("actions.undo")}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button onClick={onSave} loading={saveActionLoading}>
                  <SaveOutlined />
                  {t("actions.save")}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button onClick={onUpload}>
                  <UploadOutlined />
                  {t("actions.upload")}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button onClick={onDownload} loading={downloadActionLoading}>
                  <DownloadOutlined />
                  {t("actions.download")}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button onClick={onExport}>
                  <DownloadOutlined />
                  {t("actions.export")}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button onClick={onAddEmployee} type="primary">
                  {t("actions.add_employee")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
