import { ACCESS_TOKEN } from "constants/common";
import { GRAPHQL_URL } from "./apolloClient";

const FetchAPI = {
  upload: (formData: FormData) => {
    const headers = new Headers();

    var requestOptions = {
      method: "POST",
      headers,
      body: formData,
    };

    const token = localStorage.getItem(ACCESS_TOKEN);
    token && headers.append("Authorization", `Bearer ${token}`);

    headers.append("apollo-require-preflight", "true");

    return fetch(GRAPHQL_URL, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const res = JSON.parse(result);
        return res;
      });
  },
};

export default FetchAPI;
