import { Row } from "antd";
import Logo from "assets/images/logo.png";
import { Outlet } from "react-router-dom";
import Style from "./styles";

export default function AuthTemplate() {
  return (
    <Style>
      <Row className="auth-container" justify="center" align="middle">
        <div className="auth-content">
          <div className="logo">
            <img src={Logo} alt="Logo" />
          </div>
          <Outlet />
        </div>
      </Row>
    </Style>
  );
}
