import {
  ExclamationCircleTwoTone
} from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Typography,
  notification,
} from "antd";
import {
  ACCESS_TOKEN,
  ALLOW_LANGUAGES,
  LANGUAGE,
  REGEX,
} from "constants/common";
import { useAppContext } from "context/AppContext";
import { useSignInMutation, useUpdateEmployeeDataMutation } from "generated/graphql";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { SignInPayload } from "types";

export default function SignIn() {
  const [signInMutation, { loading }] = useSignInMutation();
  const [updateEmployeeDataMutation] = useUpdateEmployeeDataMutation();

  const navigate = useNavigate();
  const { setIsLogined, setLanguage, language } = useAppContext();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onSubmit = async (values: SignInPayload) => {
    try {
      const res = await signInMutation({
        variables: values,
      });
      if (res && res.data && res.data.signIn) {
        localStorage.setItem(ACCESS_TOKEN, res.data.signIn.access_token);
        localStorage.setItem(LANGUAGE, language);
        setIsLogined(true);
        setLanguage(language);
        notification.success({
          message: t("messages.success.sign_in.message"),
          description: t("messages.success.sign_in.description"),
        });
        if (res.data.signIn.update) {
          Modal.confirm({
            title: t("modal.user.update_employee_data.message"),
            icon: <ExclamationCircleTwoTone twoToneColor="#ff4d4f" />,
            content: t("modal.user.update_employee_data.description"),
            onOk: async () => {
              try {
                await updateEmployeeDataMutation();
                navigate("/");
              } catch (error) {
                console.error("Cannot update employee data:", error);
              }
            },
            cancelText: t("actions.cancel"),
            okText: t("actions.ok"),
          });
        }
        return navigate("/");
      }
    } catch (error: any) {
      notification.error({
        message: t("messages.error.sign_in.message"),
        description: error.message,
      });
    }
  };

  const onLanguageChange = (value: string) => {
    setLanguage(value);
    navigate(`/${value}/sign-in`);
  };

  useEffect(() => {
    form.resetFields();
  }, [language, form]);

  return (
    <Card style={{ maxWidth: 420, minWidth: 360 }}>
      <Form
        form={form}
        initialValues={{
          language,
        }}
        layout="vertical"
        onFinish={onSubmit}
      >
        <Form.Item
          label={t("sign_in.email")}
          name="email"
          rules={[
            {
              type: "email",
              message: t("rules.invalid", { field: t("sign_in.email") }),
            },
            {
              required: true,
              message: t("rules.required", { field: t("sign_in.email") }),
              whitespace: false,
            },
          ]}
        >
          <Input placeholder={t("placeholder.sign_in.email")} />
        </Form.Item>
        <Form.Item
          label={t("sign_in.password")}
          name="password"
          rules={[
            {
              required: true,
              message: t("rules.required", { field: t("sign_in.password") }),
              whitespace: false,
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.resolve();
                }

                if (!REGEX.PASSWORD.test(value.toString())) {
                  return Promise.reject(t("rules.password"));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password placeholder={t("placeholder.sign_in.password")} />
        </Form.Item>
        <Form.Item
          label={t("sign_in.company_id")}
          name="companyRegistrationNumber"
          rules={[
            {
              required: true,
              message: t("rules.required", {
                field: t("sign_in.company_id"),
              }),
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.resolve();
                }

                // if (!REGEX.INTEGER.test(value.toString())) {
                if (value.toString().length < 1) {
                  return Promise.reject(
                    t("rules.invalid", { field: t("sign_in.company_id") })
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            className="w-full"
            placeholder={t("placeholder.sign_in.company_id")}
          />
        </Form.Item>
        <Form.Item label={t("sign_in.language")} name="language">
          <Select onChange={onLanguageChange}>
            {ALLOW_LANGUAGES.map((lang) => (
              <Select.Option value={lang.value} key={lang.value}>
                {lang.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Space direction="vertical" className="w-full">
          <Link to={`/${language}/forgot-password`}>
            {t("sign_in.forgot_password")}
          </Link>
          <Space align="center" className="w-full" direction="vertical">
            <Button loading={loading} htmlType="submit" type="primary">
              {t("actions.sign_in")}
            </Button>
            <Space>
              <Typography.Text>{t("sign_in.no_account_text")}</Typography.Text>
              <Link to={`/${language}/sign-up`}>{t("actions.sign_up")}</Link>
            </Space>
          </Space>
        </Space>
      </Form>
    </Card>
  );
}
