import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";

export interface IUsersFilterProps {
  onAddUsers: () => void;
  onCloseAccount: () => void;
  onSetAlias: () => void;
}
export default function UsersFilter({
  onAddUsers,
  onCloseAccount,
  onSetAlias
}: IUsersFilterProps) {
  const { t } = useTranslation();
  return (
    <Row justify="end">
      <Col>
        <Button onClick={onCloseAccount} type="primary">
          {t("actions.close_account")}
        </Button>
      </Col>
      <Col style={{ marginLeft: "10px" }}>
        <Button onClick={onSetAlias} type="primary">
          {t("actions.set_alias")}
        </Button>
      </Col>
      <Col style={{ marginLeft: "10px" }}>
        <Button onClick={onAddUsers} type="primary">
          {t("actions.add_user")}
        </Button>
      </Col>
    </Row>
  );
}
