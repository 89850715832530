import styled from "styled-components";

interface Props {
  width: string;
  collapsed: string;
}

const Style = styled.div<Props>`
  .logo {
    padding: ${(props) => (props.collapsed === "true" ? "12px" : "16px")};
    height: 64px;
    box-sizing: border-box;
    margin-bottom: 12px;
  }

  .logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .layout-header {
    position: fixed;
    top: 0;
    width: calc(100% - ${(props) => props.width});
    padding: 0;
    z-index: 10;
  }

  .layout-content {
    margin: 80px 16px 64px;
    overflow: initial;
  }

  .layout-footer {
    position: fixed;
    bottom: 0;
    width: calc(100% - ${(props) => props.width});
    padding: 16px;
    text-align: center;
    z-index: 10;
  }
`;

export default Style;
