import Logo from "assets/images/logo.png";
import { Link } from "react-router-dom";

export default function SiderLogo() {
  return (
    <Link to="/">
      <div className="logo">
        <img src={Logo} alt="Logo" />
      </div>
    </Link>
  );
}
