import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Upload: { input: any; output: any; }
};

export type Employee = {
  __typename?: 'Employee';
  TIN?: Maybe<Scalars['String']['output']>;
  account_number?: Maybe<Scalars['String']['output']>;
  accrued_earning?: Maybe<Scalars['Float']['output']>;
  employee_id?: Maybe<Scalars['String']['output']>;
  employee_status?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  pay_date?: Maybe<Scalars['String']['output']>;
  payment_amount?: Maybe<Scalars['Float']['output']>;
  routing_number?: Maybe<Scalars['String']['output']>;
  salary?: Maybe<Scalars['Float']['output']>;
};

export type EmployeePayDates = {
  __typename?: 'EmployeePayDates';
  pay_dates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum EmployeeStatusEnum {
  D = 'd',
  M = 'm',
  R = 'r'
}

export type EmployeesData = {
  __typename?: 'EmployeesData';
  employees?: Maybe<Array<Maybe<Employee>>>;
  pagination?: Maybe<Pagination>;
};

export type ExportEmpData = {
  __typename?: 'ExportEmpData';
  data?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>>;
  file_name?: Maybe<Scalars['String']['output']>;
};

export type File = {
  __typename?: 'File';
  message?: Maybe<Scalars['String']['output']>;
};

export type Token = {
  __typename?: 'Token';
  access_token: Maybe<Scalars['String']['output']>;
  update: Maybe<Scalars['Boolean']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addEmployee?: Maybe<Scalars['String']['output']>;
  addUser?: Maybe<Scalars['String']['output']>;
  clearAccruedEarning?: Maybe<Scalars['String']['output']>;
  clearPaydate?: Maybe<Scalars['String']['output']>;
  clearPaymentAmount?: Maybe<Scalars['String']['output']>;
  clearSalary?: Maybe<Scalars['String']['output']>;
  closeAccount?: Maybe<Scalars['String']['output']>;
  deleteEmployee?: Maybe<Scalars['String']['output']>;
  deleteUser?: Maybe<Scalars['String']['output']>;
  downloadEmployees?: Maybe<ExportEmpData>;
  downloadPaymentData?: Maybe<ExportEmpData>;
  downloadPayrollData?: Maybe<ExportEmpData>;
  resendMail?: Maybe<Scalars['String']['output']>;
  resetPassword?: Maybe<Scalars['String']['output']>;
  saveEmployees?: Maybe<Scalars['String']['output']>;
  setAlias?: Maybe<Scalars['String']['output']>;
  setPassword?: Maybe<Scalars['String']['output']>;
  setPayDate?: Maybe<Scalars['String']['output']>;
  signIn?: Maybe<Token>;
  signUp?: Maybe<Scalars['String']['output']>;
  updateEmployee?: Maybe<Scalars['String']['output']>;
  updateEmployeeData?: Maybe<Scalars['String']['output']>;
  updateUser?: Maybe<Scalars['String']['output']>;
  uploadEmailAttachment?: Maybe<File>;
  uploadEmployees?: Maybe<File>;
  uploadKey?: Maybe<File>;
};


export type MutationAddEmployeeArgs = {
  TIN: Scalars['String']['input'];
  account_number?: InputMaybe<Scalars['String']['input']>;
  accrued_earning?: InputMaybe<Scalars['Float']['input']>;
  employee_id?: InputMaybe<Scalars['String']['input']>;
  employee_status?: InputMaybe<EmployeeStatusEnum>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  pay_date?: InputMaybe<Scalars['String']['input']>;
  payment_amount?: InputMaybe<Scalars['Float']['input']>;
  routing_number?: InputMaybe<Scalars['String']['input']>;
  salary?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationAddUserArgs = {
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationClearAccruedEarningArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type MutationClearPaydateArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type MutationClearPaymentAmountArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type MutationClearSalaryArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type MutationDeleteEmployeeArgs = {
  ids: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type MutationDeleteUserArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDownloadPaymentDataArgs = {
  pay_date: Scalars['String']['input'];
};


export type MutationResendMailArgs = {
  user_id: Scalars['Int']['input'];
};


export type MutationResetPasswordArgs = {
  company_registration_number: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


export type MutationSaveEmployeesArgs = {
  input?: InputMaybe<Array<InputMaybe<SaveEmployeesInput>>>;
};


export type MutationSetAliasArgs = {
  alias: Scalars['String']['input'];
};


export type MutationSetPasswordArgs = {
  email_token: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationSetPayDateArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  pay_date: Scalars['String']['input'];
};


export type MutationSignInArgs = {
  company_registration_number: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationSignUpArgs = {
  company_registration_number: Scalars['String']['input'];
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationUpdateEmployeeArgs = {
  account_number?: InputMaybe<Scalars['String']['input']>;
  accrued_earning?: InputMaybe<Scalars['Float']['input']>;
  employee_id?: InputMaybe<Scalars['String']['input']>;
  employee_status?: InputMaybe<EmployeeStatusEnum>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  last_name?: InputMaybe<Scalars['String']['input']>;
  pay_date?: InputMaybe<Scalars['String']['input']>;
  payment_amount?: InputMaybe<Scalars['Float']['input']>;
  routing_number?: InputMaybe<Scalars['String']['input']>;
  salary?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdateUserArgs = {
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  user_id: Scalars['Int']['input'];
};


export type MutationUploadEmailAttachmentArgs = {
  file: Scalars['Upload']['input'];
  email: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUploadEmployeesArgs = {
  file: Scalars['Upload']['input'];
};

export type MutationUploadKeyArgs = {
  file: Scalars['Upload']['input'];
};

export type Pagination = {
  __typename?: 'Pagination';
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  total_page?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  checkHealth?: Maybe<Scalars['String']['output']>;
  getAlias?: Maybe<Scalars['String']['output']>;
  getEmployeePaydates?: Maybe<EmployeePayDates>;
  getEmployees?: Maybe<EmployeesData>;
  getUsers?: Maybe<Array<Maybe<User>>>;
  testPermission?: Maybe<Scalars['String']['output']>;
};


export type QueryGetEmployeesArgs = {
  employee_status?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type SaveEmployeesInput = {
  accrued_earning?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Int']['input'];
  pay_date?: InputMaybe<Scalars['String']['input']>;
  payment_amount?: InputMaybe<Scalars['Float']['input']>;
  salary?: InputMaybe<Scalars['Float']['input']>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
};

export type AddEmployeeMutationVariables = Exact<{
  tin: Scalars['String']['input'];
  employeeId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  employeeStatus?: InputMaybe<EmployeeStatusEnum>;
  routingNumber?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  salary?: InputMaybe<Scalars['Float']['input']>;
  accruedEarning?: InputMaybe<Scalars['Float']['input']>;
  payDate?: InputMaybe<Scalars['String']['input']>;
  paymentAmount?: InputMaybe<Scalars['Float']['input']>;
}>;


export type AddEmployeeMutation = { __typename?: 'Mutation', addEmployee?: string | null };

export type AddUserMutationVariables = Exact<{
  firstName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddUserMutation = { __typename?: 'Mutation', addUser?: string | null };

export type ClearAccruedEarningMutationVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
}>;


export type ClearAccruedEarningMutation = { __typename?: 'Mutation', clearAccruedEarning?: string | null };

export type ClearPaydateMutationVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
}>;


export type ClearPaydateMutation = { __typename?: 'Mutation', clearPaydate?: string | null };

export type ClearPaymentAmountMutationVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
}>;


export type ClearPaymentAmountMutation = { __typename?: 'Mutation', clearPaymentAmount?: string | null };

export type ClearSalaryMutationVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
}>;


export type ClearSalaryMutation = { __typename?: 'Mutation', clearSalary?: string | null };

export type CloseAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type CloseAccountMutation = { __typename?: 'Mutation', closeAccount?: string | null };

export type DeleteEmployeeMutationVariables = Exact<{
  ids: Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteEmployeeMutation = { __typename?: 'Mutation', deleteEmployee?: string | null };

export type DeleteUserMutationVariables = Exact<{
  deleteUserId: Scalars['Int']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: string | null };

export type DownloadEmployeesMutationVariables = Exact<{ [key: string]: never; }>;


export type DownloadEmployeesMutation = { __typename?: 'Mutation', downloadEmployees?: { __typename?: 'ExportEmpData', data?: Array<Array<string | null> | null> | null, file_name?: string | null } | null };

export type UpdateEmployeeMutationVariables = Exact<{
  updateEmployeeId: Scalars['Int']['input'];
  employeeId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  employeeStatus?: InputMaybe<EmployeeStatusEnum>;
  routingNumber?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  salary?: InputMaybe<Scalars['Float']['input']>;
  accruedEarning?: InputMaybe<Scalars['Float']['input']>;
  payDate?: InputMaybe<Scalars['String']['input']>;
  paymentAmount?: InputMaybe<Scalars['Float']['input']>;
}>;


export type UpdateEmployeeMutation = { __typename?: 'Mutation', updateEmployee?: string | null };

export type DownloadPaymentDataMutationVariables = Exact<{
  payDate: Scalars['String']['input'];
}>;


export type DownloadPaymentDataMutation = { __typename?: 'Mutation', downloadPaymentData?: { __typename?: 'ExportEmpData', data?: Array<Array<string | null> | null> | null, file_name?: string | null } | null };

export type DownloadPayrollDataMutationVariables = Exact<{ [key: string]: never; }>;


export type DownloadPayrollDataMutation = { __typename?: 'Mutation', downloadPayrollData?: { __typename?: 'ExportEmpData', file_name?: string | null, data?: Array<Array<string | null> | null> | null } | null };

export type GetAliasQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAliasQuery = { __typename?: 'Query', getAlias: string };

export type GetEmployeePaydatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmployeePaydatesQuery = { __typename?: 'Query', getEmployeePaydates?: { __typename?: 'EmployeePayDates', pay_dates?: Array<string | null> | null } | null };

export type GetEmployeesQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  employeeStatus?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetEmployeesQuery = { __typename?: 'Query', getEmployees?: { __typename?: 'EmployeesData', employees?: Array<{ __typename?: 'Employee', id?: number | null, employee_id?: string | null, TIN?: string | null, first_name?: string | null, last_name?: string | null, employee_status?: string | null, routing_number?: string | null, account_number?: string | null, salary?: number | null, accrued_earning?: number | null, pay_date?: string | null, payment_amount?: number | null } | null> | null, pagination?: { __typename?: 'Pagination', page?: number | null, limit?: number | null, total_page?: number | null, total?: number | null } | null } | null };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', getUsers?: Array<{ __typename?: 'User', id?: number | null, email?: string | null, role?: string | null, first_name?: string | null, last_name?: string | null, status?: boolean | null } | null> | null };

export type ResendMailMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;


export type ResendMailMutation = { __typename?: 'Mutation', resendMail?: string | null };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
  companyRegistrationNumber: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: string | null };

export type SaveEmployeesMutationVariables = Exact<{
  input?: InputMaybe<Array<InputMaybe<SaveEmployeesInput>> | InputMaybe<SaveEmployeesInput>>;
}>;


export type SaveEmployeesMutation = { __typename?: 'Mutation', saveEmployees?: string | null };

export type SetAliasMutationVariables = Exact<{
  alias: Scalars['String']['input'];
}>;


export type SetAliasMutation = { __typename?: 'Mutation', setAlias?: string | null };

export type SetPasswordMutationVariables = Exact<{
  emailToken: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type SetPasswordMutation = { __typename?: 'Mutation', setPassword?: string | null };

export type SetPayDateMutationVariables = Exact<{
  payDate: Scalars['String']['input'];
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
}>;


export type SetPayDateMutation = { __typename?: 'Mutation', setPayDate?: string | null };

export type SignInMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  companyRegistrationNumber: Scalars['String']['input'];
}>;


export type SignInMutation = { __typename?: 'Mutation', signIn?: { __typename?: 'Token', access_token: string, update: boolean } | null };

export type SignUpMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  companyRegistrationNumber: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp?: string | null };

export type UpdateEmployeeDataMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateEmployeeDataMutation = { __typename?: 'Mutation', updateEmployeeData?: string | null };

export type UpdateUserMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
  status?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: string | null };

export type UploadEmailAttachmentMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
  email: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}>;


export type UploadEmailAttachmentMutation = { __typename?: 'Mutation', uploadEmailAttachment?: { __typename?: 'File', message?: string | null } | null };

export type UploadEmployeesMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;


export type UploadEmployeesMutation = { __typename?: 'Mutation', uploadKey?: { __typename?: 'File', message?: string | null } | null };


export type UploadKeyMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;


export type UploadKeyMutation = { __typename?: 'Mutation', uploadKey?: { __typename?: 'File', message?: string | null } | null };


export const AddEmployeeDocument = gql`
    mutation AddEmployee($tin: String!, $employeeId: String, $firstName: String, $lastName: String, $employeeStatus: EmployeeStatusEnum, $routingNumber: String, $accountNumber: String, $salary: Float, $accruedEarning: Float, $payDate: String, $paymentAmount: Float) {
  addEmployee(
    TIN: $tin
    employee_id: $employeeId
    first_name: $firstName
    last_name: $lastName
    employee_status: $employeeStatus
    routing_number: $routingNumber
    account_number: $accountNumber
    salary: $salary
    accrued_earning: $accruedEarning
    pay_date: $payDate
    payment_amount: $paymentAmount
  )
}
    `;
export type AddEmployeeMutationFn = Apollo.MutationFunction<AddEmployeeMutation, AddEmployeeMutationVariables>;

/**
 * __useAddEmployeeMutation__
 *
 * To run a mutation, you first call `useAddEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmployeeMutation, { data, loading, error }] = useAddEmployeeMutation({
 *   variables: {
 *      tin: // value for 'tin'
 *      employeeId: // value for 'employeeId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      employeeStatus: // value for 'employeeStatus'
 *      routingNumber: // value for 'routingNumber'
 *      accountNumber: // value for 'accountNumber'
 *      salary: // value for 'salary'
 *      accruedEarning: // value for 'accruedEarning'
 *      payDate: // value for 'payDate'
 *      paymentAmount: // value for 'paymentAmount'
 *   },
 * });
 */
export function useAddEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<AddEmployeeMutation, AddEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEmployeeMutation, AddEmployeeMutationVariables>(AddEmployeeDocument, options);
      }
export type AddEmployeeMutationHookResult = ReturnType<typeof useAddEmployeeMutation>;
export type AddEmployeeMutationResult = Apollo.MutationResult<AddEmployeeMutation>;
export type AddEmployeeMutationOptions = Apollo.BaseMutationOptions<AddEmployeeMutation, AddEmployeeMutationVariables>;
export const AddUserDocument = gql`
    mutation AddUser($firstName: String!, $email: String!, $lastName: String) {
  addUser(first_name: $firstName, email: $email, last_name: $lastName)
}
    `;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      email: // value for 'email'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const ClearAccruedEarningDocument = gql`
    mutation ClearAccruedEarning($ids: [Int]) {
  clearAccruedEarning(ids: $ids)
}
    `;
export type ClearAccruedEarningMutationFn = Apollo.MutationFunction<ClearAccruedEarningMutation, ClearAccruedEarningMutationVariables>;

/**
 * __useClearAccruedEarningMutation__
 *
 * To run a mutation, you first call `useClearAccruedEarningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearAccruedEarningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearAccruedEarningMutation, { data, loading, error }] = useClearAccruedEarningMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useClearAccruedEarningMutation(baseOptions?: Apollo.MutationHookOptions<ClearAccruedEarningMutation, ClearAccruedEarningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearAccruedEarningMutation, ClearAccruedEarningMutationVariables>(ClearAccruedEarningDocument, options);
      }
export type ClearAccruedEarningMutationHookResult = ReturnType<typeof useClearAccruedEarningMutation>;
export type ClearAccruedEarningMutationResult = Apollo.MutationResult<ClearAccruedEarningMutation>;
export type ClearAccruedEarningMutationOptions = Apollo.BaseMutationOptions<ClearAccruedEarningMutation, ClearAccruedEarningMutationVariables>;
export const ClearPaydateDocument = gql`
    mutation ClearPaydate($ids: [Int]) {
  clearPaydate(ids: $ids)
}
    `;
export type ClearPaydateMutationFn = Apollo.MutationFunction<ClearPaydateMutation, ClearPaydateMutationVariables>;

/**
 * __useClearPaydateMutation__
 *
 * To run a mutation, you first call `useClearPaydateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearPaydateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearPaydateMutation, { data, loading, error }] = useClearPaydateMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useClearPaydateMutation(baseOptions?: Apollo.MutationHookOptions<ClearPaydateMutation, ClearPaydateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearPaydateMutation, ClearPaydateMutationVariables>(ClearPaydateDocument, options);
      }
export type ClearPaydateMutationHookResult = ReturnType<typeof useClearPaydateMutation>;
export type ClearPaydateMutationResult = Apollo.MutationResult<ClearPaydateMutation>;
export type ClearPaydateMutationOptions = Apollo.BaseMutationOptions<ClearPaydateMutation, ClearPaydateMutationVariables>;
export const ClearPaymentAmountDocument = gql`
    mutation ClearPaymentAmount($ids: [Int]) {
  clearPaymentAmount(ids: $ids)
}
    `;
export type ClearPaymentAmountMutationFn = Apollo.MutationFunction<ClearPaymentAmountMutation, ClearPaymentAmountMutationVariables>;

/**
 * __useClearPaymentAmountMutation__
 *
 * To run a mutation, you first call `useClearPaymentAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearPaymentAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearPaymentAmountMutation, { data, loading, error }] = useClearPaymentAmountMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useClearPaymentAmountMutation(baseOptions?: Apollo.MutationHookOptions<ClearPaymentAmountMutation, ClearPaymentAmountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearPaymentAmountMutation, ClearPaymentAmountMutationVariables>(ClearPaymentAmountDocument, options);
      }
export type ClearPaymentAmountMutationHookResult = ReturnType<typeof useClearPaymentAmountMutation>;
export type ClearPaymentAmountMutationResult = Apollo.MutationResult<ClearPaymentAmountMutation>;
export type ClearPaymentAmountMutationOptions = Apollo.BaseMutationOptions<ClearPaymentAmountMutation, ClearPaymentAmountMutationVariables>;
export const ClearSalaryDocument = gql`
    mutation ClearSalary($ids: [Int]) {
  clearSalary(ids: $ids)
}
    `;
export type ClearSalaryMutationFn = Apollo.MutationFunction<ClearSalaryMutation, ClearSalaryMutationVariables>;

/**
 * __useClearSalaryMutation__
 *
 * To run a mutation, you first call `useClearSalaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearSalaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearSalaryMutation, { data, loading, error }] = useClearSalaryMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useClearSalaryMutation(baseOptions?: Apollo.MutationHookOptions<ClearSalaryMutation, ClearSalaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearSalaryMutation, ClearSalaryMutationVariables>(ClearSalaryDocument, options);
      }
export type ClearSalaryMutationHookResult = ReturnType<typeof useClearSalaryMutation>;
export type ClearSalaryMutationResult = Apollo.MutationResult<ClearSalaryMutation>;
export type ClearSalaryMutationOptions = Apollo.BaseMutationOptions<ClearSalaryMutation, ClearSalaryMutationVariables>;
export const CloseAccountDocument = gql`
    mutation CloseAccount {
  closeAccount
}
    `;
export type CloseAccountMutationFn = Apollo.MutationFunction<CloseAccountMutation, CloseAccountMutationVariables>;

/**
 * __useCloseAccountMutation__
 *
 * To run a mutation, you first call `useCloseAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeAccountMutation, { data, loading, error }] = useCloseAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useCloseAccountMutation(baseOptions?: Apollo.MutationHookOptions<CloseAccountMutation, CloseAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseAccountMutation, CloseAccountMutationVariables>(CloseAccountDocument, options);
      }
export type CloseAccountMutationHookResult = ReturnType<typeof useCloseAccountMutation>;
export type CloseAccountMutationResult = Apollo.MutationResult<CloseAccountMutation>;
export type CloseAccountMutationOptions = Apollo.BaseMutationOptions<CloseAccountMutation, CloseAccountMutationVariables>;
export const DeleteEmployeeDocument = gql`
    mutation DeleteEmployee($ids: [Int]!) {
  deleteEmployee(ids: $ids)
}
    `;
export type DeleteEmployeeMutationFn = Apollo.MutationFunction<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>;

/**
 * __useDeleteEmployeeMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeMutation, { data, loading, error }] = useDeleteEmployeeMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>(DeleteEmployeeDocument, options);
      }
export type DeleteEmployeeMutationHookResult = ReturnType<typeof useDeleteEmployeeMutation>;
export type DeleteEmployeeMutationResult = Apollo.MutationResult<DeleteEmployeeMutation>;
export type DeleteEmployeeMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($deleteUserId: Int!) {
  deleteUser(id: $deleteUserId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      deleteUserId: // value for 'deleteUserId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DownloadEmployeesDocument = gql`
    mutation DownloadEmployees {
  downloadEmployees {
    data
    file_name
  }
}
    `;
export type DownloadEmployeesMutationFn = Apollo.MutationFunction<DownloadEmployeesMutation, DownloadEmployeesMutationVariables>;

/**
 * __useDownloadEmployeesMutation__
 *
 * To run a mutation, you first call `useDownloadEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadEmployeesMutation, { data, loading, error }] = useDownloadEmployeesMutation({
 *   variables: {
 *   },
 * });
 */
export function useDownloadEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<DownloadEmployeesMutation, DownloadEmployeesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadEmployeesMutation, DownloadEmployeesMutationVariables>(DownloadEmployeesDocument, options);
      }
export type DownloadEmployeesMutationHookResult = ReturnType<typeof useDownloadEmployeesMutation>;
export type DownloadEmployeesMutationResult = Apollo.MutationResult<DownloadEmployeesMutation>;
export type DownloadEmployeesMutationOptions = Apollo.BaseMutationOptions<DownloadEmployeesMutation, DownloadEmployeesMutationVariables>;
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($updateEmployeeId: Int!, $employeeId: String, $firstName: String, $lastName: String, $employeeStatus: EmployeeStatusEnum, $routingNumber: String, $accountNumber: String, $salary: Float, $accruedEarning: Float, $payDate: String, $paymentAmount: Float) {
  updateEmployee(
    id: $updateEmployeeId
    employee_id: $employeeId
    first_name: $firstName
    last_name: $lastName
    employee_status: $employeeStatus
    routing_number: $routingNumber
    account_number: $accountNumber
    salary: $salary
    accrued_earning: $accruedEarning
    pay_date: $payDate
    payment_amount: $paymentAmount
  )
}
    `;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      updateEmployeeId: // value for 'updateEmployeeId'
 *      employeeId: // value for 'employeeId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      employeeStatus: // value for 'employeeStatus'
 *      routingNumber: // value for 'routingNumber'
 *      accountNumber: // value for 'accountNumber'
 *      salary: // value for 'salary'
 *      accruedEarning: // value for 'accruedEarning'
 *      payDate: // value for 'payDate'
 *      paymentAmount: // value for 'paymentAmount'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, options);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const DownloadPaymentDataDocument = gql`
    mutation DownloadPaymentData($payDate: String!) {
  downloadPaymentData(pay_date: $payDate) {
    data
    file_name
  }
}
    `;
export type DownloadPaymentDataMutationFn = Apollo.MutationFunction<DownloadPaymentDataMutation, DownloadPaymentDataMutationVariables>;

/**
 * __useDownloadPaymentDataMutation__
 *
 * To run a mutation, you first call `useDownloadPaymentDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadPaymentDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadPaymentDataMutation, { data, loading, error }] = useDownloadPaymentDataMutation({
 *   variables: {
 *      payDate: // value for 'payDate'
 *   },
 * });
 */
export function useDownloadPaymentDataMutation(baseOptions?: Apollo.MutationHookOptions<DownloadPaymentDataMutation, DownloadPaymentDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadPaymentDataMutation, DownloadPaymentDataMutationVariables>(DownloadPaymentDataDocument, options);
      }
export type DownloadPaymentDataMutationHookResult = ReturnType<typeof useDownloadPaymentDataMutation>;
export type DownloadPaymentDataMutationResult = Apollo.MutationResult<DownloadPaymentDataMutation>;
export type DownloadPaymentDataMutationOptions = Apollo.BaseMutationOptions<DownloadPaymentDataMutation, DownloadPaymentDataMutationVariables>;
export const DownloadPayrollDataDocument = gql`
    mutation DownloadPayrollData {
  downloadPayrollData {
    file_name
    data
  }
}
    `;
export type DownloadPayrollDataMutationFn = Apollo.MutationFunction<DownloadPayrollDataMutation, DownloadPayrollDataMutationVariables>;

/**
 * __useDownloadPayrollDataMutation__
 *
 * To run a mutation, you first call `useDownloadPayrollDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadPayrollDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadPayrollDataMutation, { data, loading, error }] = useDownloadPayrollDataMutation({
 *   variables: {
 *   },
 * });
 */
export function useDownloadPayrollDataMutation(baseOptions?: Apollo.MutationHookOptions<DownloadPayrollDataMutation, DownloadPayrollDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadPayrollDataMutation, DownloadPayrollDataMutationVariables>(DownloadPayrollDataDocument, options);
      }
export type DownloadPayrollDataMutationHookResult = ReturnType<typeof useDownloadPayrollDataMutation>;
export type DownloadPayrollDataMutationResult = Apollo.MutationResult<DownloadPayrollDataMutation>;
export type DownloadPayrollDataMutationOptions = Apollo.BaseMutationOptions<DownloadPayrollDataMutation, DownloadPayrollDataMutationVariables>;
export const GetAliasDocument = gql`
    query GetAlias {
  getAlias 
}
    `;

/**
 * __useGetAliasQuery__
 *
 * To run a query within a React component, call `useGetAliasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAliasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAliasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAliasQuery(baseOptions?: Apollo.QueryHookOptions<GetAliasQuery, GetAliasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAliasQuery, GetAliasQueryVariables>(GetAliasDocument, options);
      }
export function useGetAliasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAliasQuery, GetAliasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAliasQuery, GetAliasQueryVariables>(GetAliasDocument, options);
        }
export type GetAliasQueryHookResult = ReturnType<typeof useGetAliasQuery>;
export type GetAliasLazyQueryHookResult = ReturnType<typeof useGetAliasLazyQuery>;
export type GetAliasQueryResult = Apollo.QueryResult<GetAliasQuery, GetAliasQueryVariables>;
export const GetEmployeePaydatesDocument = gql`
    query GetEmployeePaydates {
  getEmployeePaydates {
    pay_dates
  }
}
    `;

/**
 * __useGetEmployeePaydatesQuery__
 *
 * To run a query within a React component, call `useGetEmployeePaydatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeePaydatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeePaydatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeePaydatesQuery(baseOptions?: Apollo.QueryHookOptions<GetEmployeePaydatesQuery, GetEmployeePaydatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeePaydatesQuery, GetEmployeePaydatesQueryVariables>(GetEmployeePaydatesDocument, options);
      }
export function useGetEmployeePaydatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeePaydatesQuery, GetEmployeePaydatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeePaydatesQuery, GetEmployeePaydatesQueryVariables>(GetEmployeePaydatesDocument, options);
        }
export type GetEmployeePaydatesQueryHookResult = ReturnType<typeof useGetEmployeePaydatesQuery>;
export type GetEmployeePaydatesLazyQueryHookResult = ReturnType<typeof useGetEmployeePaydatesLazyQuery>;
export type GetEmployeePaydatesQueryResult = Apollo.QueryResult<GetEmployeePaydatesQuery, GetEmployeePaydatesQueryVariables>;
export const GetEmployeesDocument = gql`
    query GetEmployees($page: Int!, $limit: Int!, $employeeStatus: String, $search: String) {
  getEmployees(
    page: $page
    limit: $limit
    employee_status: $employeeStatus
    search: $search
  ) {
    employees {
      id
      employee_id
      TIN
      first_name
      last_name
      employee_status
      routing_number
      account_number
      salary
      accrued_earning
      pay_date
      payment_amount
    }
    pagination {
      page
      limit
      total_page
      total
    }
  }
}
    `;

/**
 * __useGetEmployeesQuery__
 *
 * To run a query within a React component, call `useGetEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      employeeStatus: // value for 'employeeStatus'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetEmployeesQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
      }
export function useGetEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
        }
export type GetEmployeesQueryHookResult = ReturnType<typeof useGetEmployeesQuery>;
export type GetEmployeesLazyQueryHookResult = ReturnType<typeof useGetEmployeesLazyQuery>;
export type GetEmployeesQueryResult = Apollo.QueryResult<GetEmployeesQuery, GetEmployeesQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  getUsers {
    id
    email
    role
    first_name
    last_name
    status
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const ResendMailDocument = gql`
    mutation ResendMail($userId: Int!) {
  resendMail(user_id: $userId)
}
    `;
export type ResendMailMutationFn = Apollo.MutationFunction<ResendMailMutation, ResendMailMutationVariables>;

/**
 * __useResendMailMutation__
 *
 * To run a mutation, you first call `useResendMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendMailMutation, { data, loading, error }] = useResendMailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendMailMutation(baseOptions?: Apollo.MutationHookOptions<ResendMailMutation, ResendMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendMailMutation, ResendMailMutationVariables>(ResendMailDocument, options);
      }
export type ResendMailMutationHookResult = ReturnType<typeof useResendMailMutation>;
export type ResendMailMutationResult = Apollo.MutationResult<ResendMailMutation>;
export type ResendMailMutationOptions = Apollo.BaseMutationOptions<ResendMailMutation, ResendMailMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($email: String!, $companyRegistrationNumber: String!) {
  resetPassword(
    email: $email
    company_registration_number: $companyRegistrationNumber
  )
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      companyRegistrationNumber: // value for 'companyRegistrationNumber'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SaveEmployeesDocument = gql`
    mutation SaveEmployees($input: [SaveEmployeesInput]) {
  saveEmployees(input: $input)
}
    `;
export type SaveEmployeesMutationFn = Apollo.MutationFunction<SaveEmployeesMutation, SaveEmployeesMutationVariables>;

/**
 * __useSaveEmployeesMutation__
 *
 * To run a mutation, you first call `useSaveEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEmployeesMutation, { data, loading, error }] = useSaveEmployeesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<SaveEmployeesMutation, SaveEmployeesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEmployeesMutation, SaveEmployeesMutationVariables>(SaveEmployeesDocument, options);
      }
export type SaveEmployeesMutationHookResult = ReturnType<typeof useSaveEmployeesMutation>;
export type SaveEmployeesMutationResult = Apollo.MutationResult<SaveEmployeesMutation>;
export type SaveEmployeesMutationOptions = Apollo.BaseMutationOptions<SaveEmployeesMutation, SaveEmployeesMutationVariables>;
export const SetAliasDocument = gql`
    mutation SetAlias($alias: String!) {
  setAlias(alias: $alias)
}
    `;
export type SetAliasMutationFn = Apollo.MutationFunction<SetAliasMutation, SetAliasMutationVariables>;

/**
 * __useSetAliasMutation__
 *
 * To run a mutation, you first call `useSetAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAliasMutation, { data, loading, error }] = useSetAliasMutation({
 *   variables: {
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useSetAliasMutation(baseOptions?: Apollo.MutationHookOptions<SetAliasMutation, SetAliasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAliasMutation, SetAliasMutationVariables>(SetAliasDocument, options);
      }
export type SetAliasMutationHookResult = ReturnType<typeof useSetAliasMutation>;
export type SetAliasMutationResult = Apollo.MutationResult<SetAliasMutation>;
export type SetAliasMutationOptions = Apollo.BaseMutationOptions<SetAliasMutation, SetAliasMutationVariables>;
export const SetPasswordDocument = gql`
    mutation SetPassword($emailToken: String!, $password: String!) {
  setPassword(email_token: $emailToken, password: $password)
}
    `;
export type SetPasswordMutationFn = Apollo.MutationFunction<SetPasswordMutation, SetPasswordMutationVariables>;

/**
 * __useSetPasswordMutation__
 *
 * To run a mutation, you first call `useSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordMutation, { data, loading, error }] = useSetPasswordMutation({
 *   variables: {
 *      emailToken: // value for 'emailToken'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetPasswordMutation, SetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPasswordMutation, SetPasswordMutationVariables>(SetPasswordDocument, options);
      }
export type SetPasswordMutationHookResult = ReturnType<typeof useSetPasswordMutation>;
export type SetPasswordMutationResult = Apollo.MutationResult<SetPasswordMutation>;
export type SetPasswordMutationOptions = Apollo.BaseMutationOptions<SetPasswordMutation, SetPasswordMutationVariables>;
export const SetPayDateDocument = gql`
    mutation SetPayDate($payDate: String!, $ids: [Int]) {
  setPayDate(pay_date: $payDate, ids: $ids)
}
    `;
export type SetPayDateMutationFn = Apollo.MutationFunction<SetPayDateMutation, SetPayDateMutationVariables>;

/**
 * __useSetPayDateMutation__
 *
 * To run a mutation, you first call `useSetPayDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPayDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPayDateMutation, { data, loading, error }] = useSetPayDateMutation({
 *   variables: {
 *      payDate: // value for 'payDate'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSetPayDateMutation(baseOptions?: Apollo.MutationHookOptions<SetPayDateMutation, SetPayDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPayDateMutation, SetPayDateMutationVariables>(SetPayDateDocument, options);
      }
export type SetPayDateMutationHookResult = ReturnType<typeof useSetPayDateMutation>;
export type SetPayDateMutationResult = Apollo.MutationResult<SetPayDateMutation>;
export type SetPayDateMutationOptions = Apollo.BaseMutationOptions<SetPayDateMutation, SetPayDateMutationVariables>;
export const SignInDocument = gql`
    mutation SignIn($email: String!, $password: String!, $companyRegistrationNumber: String!) {
  signIn(
    email: $email
    password: $password
    company_registration_number: $companyRegistrationNumber
  ) {
    access_token
    update
  }
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      companyRegistrationNumber: // value for 'companyRegistrationNumber'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($email: String!, $password: String!, $companyRegistrationNumber: String!, $firstName: String!, $lastName: String!) {
  signUp(
    email: $email
    password: $password
    company_registration_number: $companyRegistrationNumber
    first_name: $firstName
    last_name: $lastName
  )
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      companyRegistrationNumber: // value for 'companyRegistrationNumber'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const UpdateEmployeeDataDocument = gql`
    mutation UpdateEmployeeData {
  updateEmployeeData
}
    `;
export type UpdateEmployeeDataMutationFn = Apollo.MutationFunction<UpdateEmployeeDataMutation, UpdateEmployeeDataMutationVariables>;

/**
 * __useUpdateEmployeeDataMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeDataMutation, { data, loading, error }] = useUpdateEmployeeDataMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateEmployeeDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeDataMutation, UpdateEmployeeDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeDataMutation, UpdateEmployeeDataMutationVariables>(UpdateEmployeeDataDocument, options);
      }
export type UpdateEmployeeDataMutationHookResult = ReturnType<typeof useUpdateEmployeeDataMutation>;
export type UpdateEmployeeDataResult = Apollo.MutationResult<UpdateEmployeeDataMutation>;
export type UpdateEmployeeDataMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeDataMutation, UpdateEmployeeDataMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($userId: Int!, $status: Boolean, $lastName: String, $firstName: String) {
  updateUser(
    user_id: $userId
    status: $status
    last_name: $lastName
    first_name: $firstName
  )
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      status: // value for 'status'
 *      lastName: // value for 'lastName'
 *      firstName: // value for 'firstName'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UploadEmailAttachmentDocument = gql`
    mutation UploadEmailAttachment($file: Upload!, $email: String!, $type: String) {
  uploadEmailAttachment(file: $file, email: $email, type: $type) {
    message
  }
}
    `;
export type UploadEmailAttachmentMutationFn = Apollo.MutationFunction<UploadEmailAttachmentMutation, UploadEmailAttachmentMutationVariables>;

/**
 * __useUploadEmailAttachmentMutation__
 *
 * To run a mutation, you first call `useUploadEmailAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEmailAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEmailAttachmentMutation, { data, loading, error }] = useUploadEmailAttachmentMutation({
 *   variables: {
 *      file: // value for 'file'
 *      email: // value for 'email'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUploadEmailAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<UploadEmailAttachmentMutation, UploadEmailAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadEmailAttachmentMutation, UploadEmailAttachmentMutationVariables>(UploadEmailAttachmentDocument, options);
      }
export type UploadEmailAttachmentMutationHookResult = ReturnType<typeof useUploadEmailAttachmentMutation>;
export type UploadEmailAttachmentMutationResult = Apollo.MutationResult<UploadEmailAttachmentMutation>;
export type UploadEmailAttachmentMutationOptions = Apollo.BaseMutationOptions<UploadEmailAttachmentMutation, UploadEmailAttachmentMutationVariables>;
export const UploadEmployeesDocument = gql`
    mutation UploadEmployees($file: Upload!) {
  uploadEmployees(file: $file) {
    message
  }
}
    `;
export type UploadEmployeesMutationFn = Apollo.MutationFunction<UploadEmployeesMutation, UploadEmployeesMutationVariables>;

/**
 * __useUploadEmployeesMutation__
 *
 * To run a mutation, you first call `useUploadEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEmployeesMutation, { data, loading, error }] = useUploadEmployeesMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<UploadEmployeesMutation, UploadEmployeesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadEmployeesMutation, UploadEmployeesMutationVariables>(UploadEmployeesDocument, options);
      }
export type UploadEmployeesMutationHookResult = ReturnType<typeof useUploadEmployeesMutation>;
export type UploadEmployeesMutationResult = Apollo.MutationResult<UploadEmployeesMutation>;
export type UploadEmployeesMutationOptions = Apollo.BaseMutationOptions<UploadEmployeesMutation, UploadEmployeesMutationVariables>;
export const UploadKeyDocument = gql`
    mutation UploadKey($file: Upload!) {
  uploadEmployees(file: $file) {
    message
  }
}
    `;
export type UploadKeyMutationFn = Apollo.MutationFunction<UploadKeyMutation, UploadKeyMutationVariables>;

/**
 * __useUploadKeyMutation__
 *
 * To run a mutation, you first call `useUploadKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadKeyMutation, { data, loading, error }] = useUploadKeyMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadKeyMutation(baseOptions?: Apollo.MutationHookOptions<UploadKeyMutation, UploadKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadKeyMutation, UploadKeyMutationVariables>(UploadKeyDocument, options);
      }
export type UploadKeyMutationHookResult = ReturnType<typeof useUploadKeyMutation>;
export type UploadKeyMutationResult = Apollo.MutationResult<UploadKeyMutation>;
export type UploadKeyMutationOptions = Apollo.BaseMutationOptions<UploadKeyMutation, UploadKeyMutationVariables>;