export const parseJwt = (token: string) => {
  var base64Url = token.split(".")[1];

  if (!base64Url) return;

  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

  if (!base64) return;

  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const isTokenExpired = (token: string) => {
  try {
    if (!token) return;
    const decoded = parseJwt(token);

    if (!decoded) return true;

    return Date.now() >= decoded.exp * 1000;
  } catch (error) {
    return true;
  }
};

export const isExpiringToken = (token: string) => {
  try {
    if (!token) return;
    const decoded = parseJwt(token);

    if (!decoded) return true;

    return decoded.exp * 1000 - Date.now() <= 60000;
  } catch (error) {
    return true;
  }
};
