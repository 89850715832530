import dayjs from "dayjs";
import "dayjs/locale/th";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

export const DateFormat = {
  dayMonthYear: "DD/MM/YYYY",
  dayMonthYearHour: "DD/MM/YYYY HH:mm",
};

export const formatDate = (val: string, format: string) => {
  if (!val) return "-";
  return date(val).format(format);
};

/**
 * @description Get current user time zone
 * @returns user time zone e.g. UTC, Asia/Bangkok
 */
export const startOfDay = (val: string) => dayjs(val).startOf("day").toDate();

export const endOfDay = (val: string) => dayjs(val).endOf("day").toDate();

export const date = (val: string) => dayjs(val ?? undefined).tz("Asia/Bangkok");
