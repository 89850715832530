import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "locales/en.json";
import th from "locales/th.json";

const resources = {
  en: {
    translation: en,
  },
  th: {
    translation: th,
  },
};

const language =
  localStorage.getItem("language") || process.env.REACT_APP_DEFAULT_LANGUAGE;

i18n.use(initReactI18next).init({
  fallbackLng: language,
  lng: language,
  resources,
});

export default i18n;
