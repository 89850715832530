import { UNLOCKED_IDS } from "constants/common";
import { createContext, useContext, useState } from "react";

interface PayrollContextInterface {
  unlockedIds: number[];
  setUnlockedIds: (ids: number[]) => void;
}

const PayrollContext = createContext<PayrollContextInterface>({
  unlockedIds: [],
  setUnlockedIds: () => {},
});

export default function PayrollProvider({ children }: any) {
  const [unlockedIds, setUnlockedIds] = useState(() => {
    const ids = localStorage.getItem(UNLOCKED_IDS);
    if (!ids) return [];
    return JSON.parse(ids);
  });

  return (
    <PayrollContext.Provider
      value={{
        unlockedIds,
        setUnlockedIds,
      }}
    >
      {children}
    </PayrollContext.Provider>
  );
}

export const usePayrollContext = () => {
  return useContext(PayrollContext);
};
