import { Modal } from "antd";
import { ACCESS_TOKEN, LANGUAGE } from "constants/common";
import { isExpiringToken, isTokenExpired, parseJwt } from "helpers/token";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { User } from "types";

interface AppContextInterface {
  isLogined: boolean;
  userInfo: (User & { exp?: number; iat?: number }) | undefined;
  setIsLogined: (value: boolean) => void;
  language: string;
  setLanguage: (value: string) => void;
}

const AppContext = createContext<AppContextInterface>({
  isLogined: false,
  userInfo: undefined,
  setIsLogined: () => {},
  setLanguage: () => {},
  language: localStorage.getItem(LANGUAGE) || "",
});

export default function AppProvider({ children }: any) {
  const token = localStorage.getItem(ACCESS_TOKEN) || "";
  const { t } = useTranslation();

  const [isLogined, setIsLogined] = useState(() => {
    if (!token) return false;
    return !isTokenExpired(token);
  });

  const [language, setLanguage] = useState(
    localStorage.getItem(LANGUAGE) ||
      process.env.REACT_APP_DEFAULT_LANGUAGE ||
      ""
  );

  const userInfo = useMemo(() => {
    return token ? parseJwt(token) : undefined;
  }, [token]);

  useEffect(() => {
    const expiredTokenTimer = setInterval(() => {
      if (isTokenExpired(token)) {
        clearInterval(expiredTokenTimer);
        setIsLogined(false);
        Modal.destroyAll();
      }
    }, 1000);

    const expiringTokenTimer = setInterval(() => {
      if (isExpiringToken(token)) {
        Modal.warning({
          title: t("modal.expire_token"),
          cancelText: t("actions.cancel"),
          okText: t("actions.ok"),
        });
        clearInterval(expiringTokenTimer);
      }
    }, 1000);

    return () => {
      clearInterval(expiredTokenTimer);
      clearInterval(expiringTokenTimer);
    };
  }, [token, t]);

  return (
    <AppContext.Provider
      value={{
        isLogined,
        userInfo,
        setIsLogined,
        language,
        setLanguage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useAppContext = () => {
  return useContext(AppContext);
};
