import { Typography } from "antd";

const getHighlightedText = (text: string, highlight: string) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <Typography.Text>
      {parts?.map((part, i) => (
        <Typography.Text
          key={i}
          style={
            part?.toLowerCase() === highlight?.toLowerCase()
              ? { backgroundColor: "#f2d91a" }
              : {}
          }
        >
          {part}
        </Typography.Text>
      )) || "-"}
    </Typography.Text>
  );
};

export { getHighlightedText };
