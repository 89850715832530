import styled from "styled-components";

const Styles = styled.div`
  .ant-input-number-group-wrapper {
    .ant-input-number-group-addon {
      padding: 8px;
    }
  }
  .ant-form-item-control-input-content {
    text-align: right;
  }
`;

export default Styles;
