import {
    Button,
    Form,
    Input,
    Modal,
    Row,
    Space,
    Typography,
    notification,
} from "antd";
import {
    useSetAliasMutation,
    useGetAliasQuery
} from "generated/graphql";
import { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    REGEX
} from "constants/common";

interface AliasData {
    alias: string;
}
  
interface AliasModalProps {
    open: boolean;
    onCancel: () => void;
}
const AliasFormModal = forwardRef(
    ({ onCancel, open }: AliasModalProps) => {
    const [form] = Form.useForm();
  
    const { data, refetch } = useGetAliasQuery();

    const [setAliasMutation] = useSetAliasMutation();
  
    const { t } = useTranslation();

    const loadProfile = (alias: string | undefined) => {
        if (alias) {
          form.setFieldsValue({alias});
        }
    }

    useEffect(() => {
        loadProfile(data?.getAlias);
    }, [data, loadProfile]);

    const onSubmit = async (values: AliasData) => {
        let res: any;
        try {
            res = await setAliasMutation({
                variables: {
                    alias: values.alias
            }});
            if (res?.data) {
                notification.success({
                  message: t("messages.success.set_alias.message"),
                  description: t("messages.success.set_alias.description")
                });
                await refetch();
                form.setFieldsValue({ alias: values.alias });
                onCancel();
            } else
                notification.error({
                    message: t("modal.alias.message"),
                    description: t("messages.error.set_alias.description")
                });
        } catch (error: any) {
            notification.error({
                message: t("modal.alias.message"),
                description: error.message
            });
        }
    };
    const onCancelModal = () => {
        form.setFieldsValue({alias: data?.getAlias});
        onCancel();
    };

    return (
        <Modal
            width={340}
            open={open}
            title={<Typography.Title level={4}>{t("modal.alias.title")}</Typography.Title>}
            onCancel={onCancel}
            footer={false}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
            >
                {" "}
                <Form.Item
                    label={t("modal.alias.alias")}
                    name="alias"
                    rules={[
                        {
                            required: false,
                            whitespace: false,
                        },
                        () => ({
                            validator(_, value) {
                                if (!value) {
                                    return Promise.resolve();
                                }

                                if (!REGEX.ALIAS.test(value.toString())) {
                                    return Promise.reject(t("rules.alias"));
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <Input placeholder={t("placeholder.alias.alias")}
                        style={{ width: "100%", maxHeight: "200px", overflowY: "auto" }}
                    />
                </Form.Item>
                <Row justify="end">
                    <Space size={16}>
                        <Button
                            onClick={onCancelModal}
                        >
                            {t("actions.cancel")}
                        </Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                        >
                            {t("actions.save")}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
        );
    }
);
  
export default AliasFormModal;
  